#wrap.cart {
    width:100%;
    float:left;
    .inner-wrap {
        max-width:1200px;
        margin:0 auto;
        padding:0 35px;
        @include media($tabletP) {
            margin: 72px auto 0;
            padding:25px;
        }
        .cart-wrap {
            overflow:hidden;
            padding:30px 0;
            @include media($tabletP) {
                margin:0;
            }
            .cart-contents {
                @include span-columns(9);
                @include media($tabletP) {
                    width:100%;
                    margin:0 0 25px;
                }
                .price-info.checkout {
                    background:#FFF;
                    float: left;
                    width: 100%;
                    padding: 15px;
                    border:1px solid #EEE;
                    margin-top:15px;
                    @include span-columns(6);
                    p {
                        margin:0 0 7px;
                        width:100%;
                        float:left;
                        color:#666;
                        @include font-size(16px);
                        strong {
                            float:right;
                        }
                        &.total {
                            padding:10px 0;
                            margin-top:10px;
                            border-top:1px solid #DDD;
                            border-bottom:1px solid #DDD;
                            color:$success;
                            @include font-size(16px);
                        }
                    }
                }
                &.checkout {
                    @include span-columns(8);
                    @include media($tabletP) {
                        width:100%;
                        margin:0 0 25px;
                    }
                    tr {
                        td.price {
                        }
                        &:last-child {
                            td.price {
                                border-bottom:1px solid #EEE !important;
                            }
                        }
                    }
                }
                .heading {
                    background:#FFF;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding: 15px;
                    border:1px solid #EEE;
                    border-bottom:0;
                    @include media($mobile) {
                        border-bottom:1px solid #EEE;
                    }
                    h1 {
                        color:#222;
                        @include font-size(24px);
                        line-height: 32px;
                        margin:0;
                        @include media($tabletP) {
                            @include font-size(20px);
                        }
                    }
                    p.items {
                        @include font-size(16px);
                        color:#666;
                        line-height:32px;
                        margin:0;
                    }
                }
                .product-list {
                    @include media($mobile) {
                        border:1px solid #EEE;
                    }
                    table {
                        background:#FFF;
                        width:100%;
                        line-height:22px;
                        margin:0;
                        @include font-size(14px);
                        thead {
                            background:#F8F8F8;
                            tr {
                                th {
                                    border:1px solid #EEE;
                                    padding: 10px 15px;
                                    &:first-child {
                                        border-right:0px;
                                        @include media($mobile) {
                                            display:none;
                                        }
                                    }
                                }
                            }
                        }
                        tbody {
                            tr {
                                border-color:#FFF;
                                td {
                                    border:1px solid #EEE;
                                    vertical-align: top;
                                    @include media($mobile) {
                                        border:0;
                                        border-bottom:1px solid #EEE;
                                    }
                                    &:before {
                                        width: 100%;
                                        @include font-size(16px);
                                    }
                                    &.remove {
                                        padding:15px 25px;
                                        a {
                                            text-align:center;
                                            width:100%;
                                            color:$error;
                                            display:block;
                                            @include font-size(24px);
                                        }
                                        @include media($mobile) {
                                            padding:0;
                                            &:before {
                                                display:none;
                                            }
                                            a {
                                                border-bottom:5px solid #EEE;
                                                display:block;
                                                float:left;
                                                width:100%;
                                                background:$error;
                                                color:#FFF;
                                                text-align:center;
                                                line-height:40px;
                                                cursor:pointer;
                                            }
                                        }
                                    }
                                    &.price {
                                        padding:15px;
                                        p {
                                            color:$success;
                                            @include font-size(18px);
                                        }
                                        @include media($mobile) {
                                            text-align:center;
                                            float:left;
                                            width:100%;
                                            padding:15px;
                                        }
                                    }
                                    &.qty-wrap {
                                        width:170px;
                                        span.cart {
                                            display:block;
                                            padding:25px 15px;
                                            width:auto;
                                            text-align:center;
                                            float:left;
                                            color:$secondary;
                                        }
                                        .qty {
                                            input {
                                                display:inline-block;
                                                height: 30px;
                                                width: 50px;
                                                text-align: center;
                                                background:#FFF;
                                                font-weight:bold;
                                                color:#222;
                                                border:1px solid #DDD;
                                                @include transition(all .2s);
                                                &:focus {
                                                    background:#EEE;
                                                }
                                            }
                                            span {
                                                display:inline-block;
                                                width: 100%;
                                                text-align: center;
                                                height: 30px;
                                                width: 30px;
                                                line-height:30px;
                                                background: #EEE;
                                                color: #AAA;
                                                cursor: pointer;
                                                @include transition(.2s all);
                                                &:hover {
                                                    color:#666;
                                                }
                                                i {
                                                    font-size: 12px;
                                                    line-height: 30px;
                                                }
                                            }
                                        }
                                        @include media($mobile) {
                                            width:100%;
                                            float:left;
                                            padding:15px 15px 0;
                                            text-align:center;
                                            .qty {
                                                padding: 10px 0 25px;
                                            }
                                        }
                                    }
                                    &.product-image {
                                        width:90px;
                                        padding:15px;
                                        vertical-align: top;
                                        @include media($mobile) {
                                            display:none;
                                        }
                                    }
                                    &.product-info {
                                        padding:10px 15px;
                                        p.name {
                                            float:left;
                                            width:100%;
                                            a {
                                                color:$secondary;
                                                margin:0 0 5px;
                                                font-weight:bold;
                                                @include font-size(16px);
                                            }
                                        }
                                        p.sdesc {
                                            color:#999;
                                            font-weight:bold;
                                            float:left;
                                            margin:0;
                                            line-height: 18px;
                                            @include font-size(12px);
                                            display:inline-block;
                                            margin-right: 15px;
                                            padding-right: 15px;
                                            border-right: 1px solid #EEE;
                                            strong {
                                                color:$primary;
                                            }
                                            &:last-child {
                                                border-right:0;
                                            }
                                        }
                                        a {
                                            float:left;
                                            margin:10px 0 0;
                                        }
                                       @include media($mobile) {
                                            text-align:center;
                                            float:left;
                                            width:100%;
                                            padding:15px;
                                            p.name {
                                                a {
                                                    text-align:center;
                                                    width:100%;
                                                    float:left;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .checkout-options {
                        float:left;
                        margin:25px 0 0;
                        padding:20px;
                        width:100%;
                        border:1px solid #EEE;
                        background:#FFF;
                        form {
                            float:left;
                            input {
                                float:left;
                                height:40px;
                                border:1px solid #DDD;
                                text-indent:5px;
                                border-right:0px;
                            }
                            button {
                                padding:0 15px;
                                float:left;
                                display:block;
                                height:40px;
                                background:$primary;
                                border:0;
                                color:#FFF;
                                @include font-size(14px);
                                @include transition(all .2s);
                                &:hover {
                                    background:darken($primary, 10%);
                                }
                            }
                            @include media($tabletP) {
                                width:100%;
                                margin:0 0 15px;
                                position:relative;
                                input {
                                    width:100%;
                                    float:left;
                                }
                                button {
                                    position:absolute;
                                    top:0;
                                    right:0;
                                }
                            }
                        }
                        a.gbtn {
                            border-radius:3px;
                            line-height:40px;
                            padding:0 15px;
                            background:$primary;
                            float:right;
                            border:2px solid $primary;
                            &:hover {
                                background:#FFF;
                                color:$primary;
                            }
                            @include media($tabletP) {
                                width:100%;
                            }
                        }
                    }
                }
            }
            .cart-options {
                background:#FFF;
                border:1px solid #EEE;
                border-top:0;
                @include span-columns(3);
                position:relative;
                padding:15px;
                @include media($tabletP) {
                    width:100%;
                    margin:0;
                }
                &.checkout {
                    @include span-columns(4);
                    padding:0;
                    @include media($tabletP) {
                        width:100%;
                        margin:0;
                    }
                    h3 {
                        color:#222;
                        @include font-size(20px);
                        float:left;
                        line-height: 22px;
                        width:100%;
                        padding:20px;
                        border-bottom:1px solid #EEE;
                        border-top:1px solid #EEE;
                        i {
                            float:right;
                        }
                    }
                    .delivery-details {
                        border-top:1px solid #EEE;
                        float:left;
                        width:100%;
                        margin-top:25px;
                    }
                    .address {
                        padding:20px 30px 5px;
                        float:left;
                        width:100%;
                        line-height:20px;
                        @include font-size(14px);
                        .input-wrap {
                            width:100%;
                            float:left;
                            margin:0 0 10px;
                            input {
                                width:100%;
                                float:left;
                                border:1px solid #EEE;
                                height:40px;
                                text-indent:5px;
                            }
                            label {
                                float:left;
                                width:100%;
                            }
                        }
                        .checkbox-wrap {
                            float:left;
                            width:100%;
                            padding:0;
                            margin:15px 0 0;
                            position:relative;
                            padding-left:20px;
                            label {
                                float:left;
                                width:100%;
                                margin:0;
                                color:$secondary;
                                line-height:27px;
                                cursor:pointer;
                                @include font-size(14px);
                            }
                            input {
                                position:absolute;
                                top:0;
                                left:0;
                                margin:7px 0px;
                            }
                            &.address-select {
                                width:100%;
                                padding:0;
                                float:left;
                                margin:0;
                                select {
                                    width:100%;
                                }
                            }
                            @include media($tabletP) {
                                width:100%;
                                &:last-child {
                                    margin:0;
                                }
                            }
                        }
                    }
                    .price-info {
                        padding:20px;
                        float:left;
                        width:100%;
                    }
                }
                .price-info, .button-zone {
                    &.button-zone {
                        float:left;
                        width:100%;
                        padding:20px;
                        margin-top:20px;
                        border-top:1px solid #EEE;
                        a.gbtn {
                            float:left;
                            margin:0;
                        }
                    }
                    p {
                        margin:0 0 7px;
                        width:100%;
                        float:left;
                        color:#666;
                        @include font-size(14px);
                        strong {
                            float:right;
                        }
                        &.total {
                            padding:10px 0;
                            margin-top:10px;
                            border-top:1px solid #DDD;
                            border-bottom:1px solid #DDD;
                            color:$success;
                            @include font-size(14px);
                        }
                    }
                }
                a.gbtn {
                    width:100%;
                    padding:15px;
                    margin-top:15px;
                    float:left;
                    border-radius:3px;
                    background:$success;
                    border:2px solid $success;
                    &:hover {
                        color:$success;
                        border:2px solid $success;
                        background: transparent;
                    }
                }
            }
        }
    }
}