#wrap.product-listing {
    width:100%;
    .inner-wrap {
        max-width:1200px;
        margin:0 auto;
        padding:0 25px;
        border-top:2px solid #FFF;
        form {
            margin:0;
        }
        .product-list-wrap {
            display: flex;
            width:100%;
            .filter-list {
                height: 0;
                overflow: hidden;
                width:100%;
                position:absolute;
                left:0;
                z-index:99999;
                padding-right:0;
                @include transition(.5s all);
                @media(min-width:768px) {
                    height:auto;
                    overflow: auto;
                    padding-right:25px;
                    position:relative;
                    width:30%;
                }
                @media(min-width:1024px) {  
                    width:25%;
                }

                .filter-options-mobile {
                    display:none;
                }
                .filter-widget {
                    width:100%;
                    margin:0 0 25px;
                    border:1px solid #DEDEDE;
                    background:#FFF;
                    #search {
                        width:calc(100% - 20px);
                    }
                    h3 {
                        padding:15px;
                        color:#666;
                        background:#F8F8F8;
                        font-weight:bold;
                        font-family:$heading;
                        text-transform: uppercase;
                        border-bottom:1px solid #DEDEDE;
                        @include font-size(13px);
                        margin:0;
                    }
                    ul {
                        margin:0;
                        padding:0;
                        li {
                            border-bottom:1px dashed #DDD;
                            list-style: none;
                            margin:0;
                            padding:0 15px;
                            @include font-size(13px);
                            .checkbox-wrap {
                                height:40px;
                                position:relative;
                                label {
                                    line-height:40px;
                                    padding-left:30px;
                                    cursor:pointer;
                                    font-weight:600;
                                    display:block;
                                    width:100%;
                                    height:40px;
                                    color:#666;
                                    text-transform: lowercase;
                                    &:first-letter {
                                        text-transform:uppercase;
                                    }
                                    small {
                                        color:$secondary;
                                    }
                                    @include media($tablet) {
                                        @include font-size(12px);
                                    }
                                    @include media($mobileL) {
                                        @include font-size(14px);
                                    }
                                }
                                .checkbox-style {
                                    width:20px;
                                    height:20px;
                                    float:left;
                                    margin-right:10px;
                                    border:1px solid $secondary  ;
                                    background:#FFF;
                                    position:absolute;
                                    top:10px;
                                    left:0;
                                    pointer-events:none;
                                }
                                &.checked {
                                    .checkbox-style {
                                        text-align:center;
                                        &:before {
                                            content:"✔";
                                            line-height:18px;
                                            width:18px;
                                            color:$secondary  ;
                                            @include font-size(15px);
                                        }
                                    }
                                }
                            }
                            &:last-child {
                                border-bottom:0;
                            }
                        }
                    }
                    &:last-child {
                        margin:0;
                        padding:0;
                    }
                }
                &.active-mobile {
                    position:fixed;
                    top:0;
                    left:0;
                    width:100%;
                    height:100vh;
                    overflow:scroll;
                    z-index:99999;
                    background:#FFF;
                    padding-top:60px;
                    display:block;
                    .filter-options-mobile {
                        display:block;
                        width:100%;
                        height:40px;
                        padding:10px;
                        border-bottom:1px solid #EEE;
                        position:fixed;
                        top:0;
                        left:0;
                        background:#FFF;
                        z-index:99999;
                        h2 {
                            text-align:center;
                            width:100%;
                            float:left;
                            font-weight:normal;
                            line-height:20px;
                            color:#333;
                            @include font-size(12px);
                        }
                        a.close-mobile {
                            line-height:40px;
                            @include font-size(12px);
                            color:#FE432D;
                            position:absolute;
                            top:0;
                            left:0;
                            font-weight:bold;
                            padding:0 10px;
                            opacity:1;
                        }
                        a.apply-mobile {
                            line-height:40px;
                            @include font-size(12px);
                            color:#1ED760;
                            position:absolute;
                            top:0;
                            right:0;
                            padding:0 10px;
                            font-weight:bold;
                            opacity:1;
                        }
                    }
                    .filter-widget {
                        border:0;
                        margin:0;
                        border-bottom:1px solid #EEE;
                        h3 {
                            border-color:#EEE;
                        }
                    }
                }
            }
            .product-wrap {
                width:75%;
                @include media($tablet) {
                    width:70%;
                }
                @include media($tabletP) {
                    width:65%;
                }
                @include media($mobileL) {
                    width:100%;
                }
                .list-heading {
                    margin:0 0 15px;
                    padding:0 0 15px;
                    border-bottom:1px solid #DDD;
                    width:100%;
                    position:relative;
                    h2 {
                        @include font-size(30px);
                        line-height:30px;
                        color:$secondary;
                        text-transform: lowercase;
                        &:first-letter {
                            text-transform: capitalize;
                        }
                        @include media($tablet) {
                            width:100%;
                            margin:0 0 5px;
                            @include font-size(24px);
                            line-height:32px;
                        }
                    }
                    .results {
                        height:30px;
                        overflow:hidden;
                        position:relative;
                        p {
                            line-height:30px;
                            font-weight:bold;
                            @include font-size(14px);
                            span {
                                color:$secondary;
                            }
                        }
                        @include media($tablet) {
                            width:100%;
                            height:20px;
                            p {
                                line-height:20px;
                                @include font-size(13px);
                            }
                        }
                    }
                }
                .filter-dropdowns {
                    width:100%;
                    border-bottom:1px solid #DDD;
                    margin:0 0 15px;
                    padding:0 0 15px;
                    .sort-by {
                        float:none !important;
                    }
                    a.sortby {
                        @include font-size(14px);
                        color:#FFF;
                        display:block;
                        padding:10px;
                        background:#FFF;
                        color:#999;
                        border:1px solid #DDD;
                        line-height:30px;
                        height:48px;
                        position:relative;
                        @include transition(.2s all);
                        @include span-columns(4);
                        &:hover {
                            border-color:$secondary;
                            color:$secondary;
                        }
                        i {
                            text-align:center;
                            position:absolute;
                            top:0;
                            right:0;
                            width:35px;
                            height:44px;
                            line-height:44px;
                            margin-top:2px;
                        }
                        @include media($mobile) {
                            @include span-columns(12);
                            margin-bottom:10px;
                        }
                    }
                    a.mobile-filter {
                        padding:0 10px;
                        background:#FFF;
                        color: #999999;
                        line-height:48px;
                        height:48px;
                        border:1px solid #DDD;
                        position:relative;
                        @include font-size(14px);
                        @include span-columns(4);
                        display:none;
                        i {
                            text-align:center;
                            position:absolute;
                            top:0;
                            right:0;
                            width:35px;
                            height:44px;
                            line-height:44px;
                            margin-top:2px;
                        }
                        @include media($mobileL) {
                            display:block;
                        }
                        @include media($mobile) {
                            @include span-columns(12);
                            margin-bottom:10px;
                        }
                    }
                    .sort-by {
                        @include span-columns(4);
                        @include media($mobile) {
                            @include span-columns(12);
                        }
                    }
                }
                .brand-list {
                    float:left;
                    width:100%;
                    margin:0 0 15px;
                    display:none;
                    ul {
                        width:100%;
                        float:left;
                        background:#FFF;
                        li {
                            width:20%;
                            float:left;
                            @include media($tablet) {
                                width:33.3333%;
                                float:left;
                            }
                            @include media($mobile) {
                                width:50%;
                                float:left;
                            }
                            .filter-box {
                                padding:5px;
                                background:#FFF;
                                label {
                                    padding:5px;
                                    cursor:pointer;
                                    border:1px solid #DDD;
                                    @include transition(.2s all);
                                    img {
                                        width:100%;
                                        float:left;
                                        opacity:0.75;
                                        @include transition(.2s all);
                                    }
                                }
                                input {
                                    display:none;
                                }
                                &:hover {
                                    label {
                                        border-color:#333;
                                    }
                                }
                                &.checked {
                                    label {
                                        border-color:$secondary;
                                        img {
                                            opacity:1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .pagination-wrap {
                    float:left;
                    width:100%;
                    padding:10px 0 0;
                    margin:10px 0 0;
                    border-top:1px dotted #DDD;
                    nav {
                        float:right;
                        ul.pagination {
                            li {
                                a {
                                    border:1px dotted #DDD;
                                    color:$secondary  ;
                                    font-weight:bold;
                                    @include font-size(14px);
                                }
                                &:last-child, &:first-child {
                                    a {
                                        border-radius:0;
                                    }
                                }
                                &.active {
                                    a {
                                        background:#EEE;
                                    }
                                }
                            }
                        }
                        @include media($tablet) {
                            text-align:center;
                            width:100%;
                            float:left;
                            ul.pagination {
                                text-align:center;
                                width:100%;
                                float:left;
                                margin:15px 0 35px;
                                li {
                                    float:none;
                                    display:inline-block;
                                }
                            }
                        }
                    }
                }
                ul.product-list { 
                    margin-top:0;
                    margin-left:-15px;
                    margin-right:-15px;
                    display: flex;
                    flex-wrap: wrap;
                    overflow: hidden;
                    padding:0;
                    li {
                        background:none;
                        float: none;
                        list-style: none;
                        margin-right:0;
                        margin-bottom: 30px;
                        padding:0 15px;
                        width:50%;
                        @media(min-width:992px) {
                            width:33.33%;
                        }
                        a {
                            background:#fff;
                            border: 1px solid #ddd;
                            .product-info {
                                h4 {
                                    line-height:22px;
                                    @include font-size(14px);
                                    font-weight:600;
                                    margin:0;
                                }
                                p {
                                    padding:10px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}