/*
 * jQuery FlexSlider v2.4.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *

/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
 .flex-container a:hover,
 .flex-slider a:hover,
 .flex-container a:focus,
 .flex-slider a:focus {
  outline: none;
}
.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.flex-pauseplay span {
  text-transform: capitalize;
}
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
 .flexslider {
  margin: 0;
  padding: 0;
}
.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}
.flexslider .slides img {
  width: 100%;
  display: block;
}
.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .flexslider .slides {
  display: block;
}
* html .flexslider .slides {
  height: 1%;
}
.no-js .flexslider .slides > li:first-child {
  display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
 .flexslider {
  margin: 0 0 60px;
  background: #ffffff;
  border: 4px solid #ffffff;
  position: relative;
  zoom: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
}
.flexslider .slides {
  zoom: 1;
}
.flexslider .slides img {
  height: auto;
}
.flex-viewport {
  max-height: 2000px;
  float: left;
  width:100%;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.loading .flex-viewport {
  max-height: 300px;
}
.carousel li {
  margin-right: 5px;
}
.flex-direction-nav {
  *height: 0;
}
.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.flex-direction-nav a:before {
  font-family: "flexslider-icon";
  font-size: 40px;
  display: inline-block;
  content: '\f001';
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
}
.flex-direction-nav a.flex-next:before {
  content: '\f002';
}
.flex-direction-nav .flex-prev {
  left: -50px;
}
.flex-direction-nav .flex-next {
  right: -50px;
  text-align: right;
}
.flexslider:hover .flex-direction-nav .flex-prev {
  opacity: 0.7;
  left: 10px;
}
.flexslider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}
.flexslider:hover .flex-direction-nav .flex-next {
  opacity: 0.7;
  right: 10px;
}
.flexslider:hover .flex-direction-nav .flex-next:hover {
  opacity: 1;
}
.flex-direction-nav .flex-disabled {
  opacity: 0!important;
  filter: alpha(opacity=0);
  cursor: default;
}
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}
.flex-pauseplay a:before {
  font-family: "flexslider-icon";
  font-size: 20px;
  display: inline-block;
  content: '\f004';
}
.flex-pauseplay a:hover {
  opacity: 1;
}
.flex-pauseplay a .flex-play:before {
  content: '\f003';
}
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: 0;
  left:0;
  text-align: center;
}
.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.flex-control-paging li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7);
}
.flex-control-paging li a.flex-active {
  background: #000;
  background: rgba(0, 0, 0, 0.9);
  cursor: default;
}
.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}
.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}
.flex-control-thumbs img {
  width: 100%;
  height: auto;
  display: block;
  opacity: .7;
  cursor: pointer;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.flex-control-thumbs img:hover {
  opacity: 1;
}
.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}
/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
 @media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px;
  }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px;
  }
}
/* Select2 Dropdown */
.select2-container {
  z-index: 10000;
  .select2-dropdown {
    border-radius: 0;
    border: 1px solid #DDD;
    border-top: 0;
    .select2-search {
      input {
        height:40px;
        text-indent:5px;
        border:1px solid #DDD;
      }
    }
    .select2-results {
      .select2-results__options {
        .select2-results__option {
          font-weight:600;
          color:#AAA;
          line-height:25px;
          text-indent:3px;
          @include font-size(14px);
          @include transition(all .2s);
          &:hover {
            background:#FFF;
            color:$primary;
          }
        }
        .select2-results__option--highlighted {
          background:#FFF;
          color:$primary;
        }
      }
    }
  }
}

/* Remodal */
/* ==========================================================================
   Remodal's necessary styles
   ========================================================================== */

   /* Hide scroll bar */

   html.remodal-is-locked {
    overflow: hidden;

    touch-action: none;
  }

  /* Anti FOUC */

  .remodal,
  [data-remodal-id] {
    display: none;
  }

  /* Necessary styles of the overlay */

  .remodal-overlay {
    position: fixed;
    z-index: 9999;
    top: -5000px;
    right: -5000px;
    bottom: -5000px;
    left: -5000px;

    display: none;
  }

  /* Necessary styles of the wrapper */

  .remodal-wrapper {
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: none;
    overflow: auto;

    text-align: center;

    -webkit-overflow-scrolling: touch;
  }

  .remodal-wrapper:after {
    display: inline-block;

    height: 100%;
    margin-left: -0.05em;

    content: "";
  }

  /* Fix iPad, iPhone glitches */

  .remodal-overlay,
  .remodal-wrapper {
    backface-visibility: hidden;
  }

  /* Necessary styles of the modal dialog */

  .remodal {
    position: relative;

    outline: none;

    text-size-adjust: 100%;
  }

  .remodal-is-initialized {
    /* Disable Anti-FOUC */
    display: inline-block;
  }

/* ==========================================================================
   Remodal's default mobile first theme
   ========================================================================== */

   /* Default theme styles for the background */

   .remodal-bg.remodal-is-opening,
   .remodal-bg.remodal-is-opened {
    filter: blur(3px);
  }

  /* Default theme styles of the overlay */

  .remodal-overlay {
    background: rgba(43, 46, 56, 0.9);
  }

  .remodal-overlay.remodal-is-opening,
  .remodal-overlay.remodal-is-closing {
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  .remodal-overlay.remodal-is-opening {
    animation-name: remodal-overlay-opening-keyframes;
  }

  .remodal-overlay.remodal-is-closing {
    animation-name: remodal-overlay-closing-keyframes;
  }

  /* Default theme styles of the wrapper */

  .remodal-wrapper {
    padding: 10px 10px 0;
  }

  /* Default theme styles of the modal dialog */

  .remodal {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;
    padding: 0;

    transform: translate3d(0, 0, 0);

    color: #2b2e38;
    background: #fff;
  }

  .remodal.remodal-is-opening,
  .remodal.remodal-is-closing {
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  .remodal.remodal-is-opening {
    animation-name: remodal-opening-keyframes;
  }

  .remodal.remodal-is-closing {
    animation-name: remodal-closing-keyframes;
  }

  /* Vertical align of the modal dialog */

  .remodal,
  .remodal-wrapper:after {
    vertical-align: middle;
  }

  /* Close button */

  .remodal-close {
    position: absolute;
    top: 20px;
    right: 20px;

    display: block;
    overflow: visible;

    width: 35px;
    height: 35px;
    margin: 0;
    padding: 0;

    cursor: pointer;
    text-decoration: none;

    color: $primaryAccount;
    border: 0;
    outline: 0;
    background: transparent;
    @include transition(all .2s);
    &:hover, &:focus {
      color:$error;
    }
  }

  .remodal-close:before {
    font-family: Arial, "Helvetica CY", "Nimbus Sans L", sans-serif !important;
    font-size: 25px;
    line-height: 35px;

    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 35px;

    content: "\00d7";
    text-align: center;
  }

  /* Dialog buttons */

  .remodal-confirm,
  .remodal-cancel {
    font: inherit;

    display: inline-block;
    overflow: visible;

    min-width: 110px;
    margin: 0;
    padding: 12px 0;

    cursor: pointer;
    transition: background 0.2s;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;

    border: 0;
    outline: 0;
  }

  .remodal-confirm {
    color: #fff;
    background: #81c784;
  }

  .remodal-confirm:hover,
  .remodal-confirm:focus {
    background: #66bb6a;
  }

  .remodal-cancel {
    color: #fff;
    background: #e57373;
  }

  .remodal-cancel:hover,
  .remodal-cancel:focus {
    background: #ef5350;
  }

  /* Remove inner padding and border in Firefox 4+ for the button tag. */

  .remodal-confirm::-moz-focus-inner,
  .remodal-cancel::-moz-focus-inner,
  .remodal-close::-moz-focus-inner {
    padding: 0;

    border: 0;
  }

/* Keyframes
========================================================================== */

@keyframes remodal-opening-keyframes {
  from {
    transform: scale(1.05);

    opacity: 0;
  }
  to {
    transform: none;

    opacity: 1;
  }
}

@keyframes remodal-closing-keyframes {
  from {
    transform: scale(1);

    opacity: 1;
  }
  to {
    transform: scale(0.95);

    opacity: 0;
  }
}

@keyframes remodal-overlay-opening-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes remodal-overlay-closing-keyframes {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Media queries
========================================================================== */

@media only screen and (min-width: 641px) {
  .remodal {
    max-width: 700px;
  }
}

/* IE8
========================================================================== */

.lt-ie9 .remodal-overlay {
  background: #2b2e38;
}

.lt-ie9 .remodal {
  width: 700px;
}

.remodal {
  border-radius:3px;
  box-shadow:0 0 3px rgba(#000, .5);
  h2 {
    float:left;
    width:100%;
    padding:25px;
    margin:0 0 25px;
    @include font-size(20px);
    border-bottom:1px solid #EEE;
    background:#F8F8F8;
    color:$primaryAccount;
    text-align:left;
    border-radius: 3px 3px 0 0;
  }
  form {
    padding:0;
    width:100%;
    float:left;
    text-align:left;
    .submit-zone, .button-zone {
      margin:20px 0 0;
      background:#F8F8F8;
      padding:15px;
      border-top:1px solid #EEE;
      float:left;
      width:100%;
      border-radius:0 0 5px 5px;
      .gbtn {
        float:right;
        background:$success;
        border-radius:3px;
        border:0;
        @include font-size(13px);
        i {
          margin-right:5px;
        }
        &:hover {
          color:#FFF;
          background: darken($success, 10%);
        }
      }
    }
    .select-wrap {
      .select2-container {
        height:45px;
        span.selection .select2-selection .select2-selection__arrow, span.selection .select2-selection .select2-selection__arrow {
          height:41px;
        }
        span.selection .select2-selection .select2-selection__rendered, span.selection .select2-selection .select2-selection__rendered {
          line-height:45px;
          @include font-size(14px);
        }
      }
    }
    .input-wrap {
     width:50%;
     float:left;
     padding:0 30px 0 10px;
     margin:0 0 15px;
     label {
      float:left;
      width:100%;
      margin:0 0 5px;
      color:#333;
      @include font-size(13px);
      em {
        color: $error;
      }
    }
    label.error {
      display:none !important;
    }
    input, select {
      float:left;
      width:100%;
      border:1px solid #DDD;
      height:45px;
      text-indent:5px;
      @include transition(all .2s);
      &:focus {
        border-color:$secondary;
      }
    }
    &:nth-child(odd) {
      padding:0 10px 0 30px;
    }
    &.error {
      .select2-container span.selection .select2-selection, .select2-container span.selection .select2-selection {
        color:#FFF;
        border-color:$error;
        background: rgba($error, .5);
      }
      .select2-container--default .select2-selection--single .select2-selection__placeholder {
        color:#FFF;
      }
      .select-wrap .select2-container span.selection .select2-selection .select2-selection__arrow b, .select2-container span.selection .select2-selection .select2-selection__arrow b {
        border-color: #FFF transparent transparent;
      }
      input {
        color:#FFF;
        border-color:$error;
        background: rgba($error, .5);
        @include placeholder {
          color:#FFF;
        }
      }
    }
    @include media($mobileL) {
      width:100%;
      padding:0 25px;
      &:nth-child(odd) {
        padding:0 25px;
      }
    }
  }
}
}

