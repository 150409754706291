#wrap.product-inner {
    width:100%;
    .inner-wrap {
        max-width:1200px;
        margin:0 auto 0;
        padding:0 35px;
        border-top:2px solid #FFF;
        @include media($tablet) {
            padding:0 25px;
        }
        .product-inner-wrap {
            margin-top:40px;
            width:100%;
            @include media($tablet) {
                margin-top:25px;
            }
            .col-one {
                width:30%;
                float:left;
                position:relative;
                @include media($tablet) {
                    width:35%;
                }
                @include media($mobileL) {
                    width:100%;
                    margin-bottom:25px;
                }
                .colours {
                    position:absolute;
                    top:0;
                    left:0;
                    width:85%;
                    padding:10px;
                    z-index:10;
                    .checkbox-wrap, .size-input {
                        width:27px;
                        height:27px;
                        float:left;
                        margin:0 5px 5px 0;
                        label.colour-box {
                            width:25px;
                            height:25px;
                            float:left;
                            border:1px solid #FFF;
                            cursor:pointer;
                            &.checked {
                                border:1px solid $primary;
                            }
                        }
                    }
                }
                .image-slider {
                    width:100%;
                    padding:5px;
                    border:1px solid #DDD;
                    background:#FFF;
                    overflow:hidden;
                    @include media ($mobileL) {
                        width:100%;
                    }
                    .flex-viewport {
                        max-height:750px;
                        img {
                            width:100%;
                        }
                    }
                    ul {
                        li {
                            list-style: none;
                            img {
                                width:100%;
                            }
                        }
                    }
                }
                .carousel-wrap {
                    padding: 0 20px;
                    width:100%;
                    #product-carousel {
                        overflow: hidden;
                        margin-bottom:5px;
                        @media (max-width: 720px) {
                          margin-bottom:10px;
                        }
                        @include media ($mobileL) {
                            display:none;
                        }
                        ul.flex-direction-nav {
                            width:100%;
                            position:absolute;
                            bottom:64px;
                            left:0;
                            li {
                                list-style: none;
                                &.flex-nav-prev {
                                    a {
                                        left:0;
                                        opacity:1;
                                        &.flex-disabled {
                                            opacity:.3 !important;
                                        }
                                        &:before {
                                            content:'\f104';
                                            font-family: FontAwesome;
                                            font-weight: normal;
                                            font-style: normal;
                                            display: inline-block;
                                            text-decoration: inherit;
                                            left:0;
                                        }
                                    }
                                }
                                &.flex-nav-next {
                                    a {
                                        right:0;
                                        opacity:1;
                                        &:before {
                                            content:'\f105';
                                            font-family: FontAwesome;
                                            font-weight: normal;
                                            font-style: normal;
                                            display: inline-block;
                                            text-decoration: inherit;
                                            right: 0;
                                        }
                                    }
                                }
                                a {
                                    margin:0;
                                    top: inherit;
                                    height: 50px;
                                    width:20px;
                                    text-indent:9999px;
                                    overflow:hidden;
                                    text-align:right;
                                    &.flex-disabled {
                                        color:$primary;
                                        opacity:.3 !important;
                                        cursor:error;
                                        &:before {
                                            color:#555;
                                        }
                                    }
                                    &:before {
                                        line-height:50px;
                                        position: absolute;
                                        top: 0;
                                        text-indent:0;
                                        @include font-size(20px);
                                        color:$primary;
                                    }
                                }
                            }
                        }
                        ul.slides {
                            margin-top:10px;
                            li.playbutton-overview {
                                list-style: none;
                                position:relative;
                                &:before {
                                  content:"";
                                  display:block;
                                  width:25px;
                                  height:25px;
                                  background:#FFF;
                                  position:absolute;
                                  top:50%;
                                  left:50%;
                                  margin-left:-12.5px;
                                  margin-top:-12.5px;
                                  z-index:5;
                                  opacity:0.75;
                                  pointer-events: none;
                                }
                                &:after {
                                  opacity:0.75;
                                  color:#555555;
                                  content: "\f04b";
                                  font-family: FontAwesome;
                                  font-style: normal;
                                  font-weight: normal;
                                  text-decoration: inherit;
                                  line-height:25px;
                                  width:25px;
                                  text-align:center;
                                  position:absolute;
                                  font-size:12px;
                                  top:50%;
                                  left:50%;
                                  margin-top:-12.5px;
                                  margin-left:-12.5px;
                                  z-index:6;
                                  pointer-events: none;
                                }
                            }
                            li {
                                background: #FFF;
                                text-align: center;
                                overflow: hidden;
                                list-style: none;
                                margin: 0 0 8px 0;
                                cursor:pointer;
                                border: 1px solid #EEE;
                                background: #FFF;
                                box-shadow:none;
                                @include transition(.2s all);
                                img {
                                  
                                  border:3px solid #FFF;
                                  display: block;
                                  width: 100%;
                                }
                            }
                            li.flex-active-slide {
                                background-color: transparent;
                                border:1px solid $secondary;
                                list-style: none;
                            }
                        }
                    }
                }
            }
            .col-two {
                width:70%;
                float:left;
                padding-left:25px;
                @include media($tablet) {
                    width:65%;
                }
                @include media($mobileL) {
                    width:100%;
                    padding:0;
                }
                .price-wrap {
                    display:block;
                    float:left;
                    border-top:1px dotted #DDD;
                    width:100%;
                    margin:20px 0 15px;
                    padding:15px 0 0;
                    h5 {
                        line-height:30px;
                        float:left;
                        color:#666;
                        @include font-size(14px);
                        a {
                            color:$success;
                        }
                    }
                    h3 {
                        color:$success;
                        font-weight:bold;
                        line-height:30px;
                        float:left;
                        @include font-size(22px);
                    }
                    p.product-code {
                        @include font-size(12px);
                        color:$primary;
                        font-weight:bold;
                        float:right;
                        line-height:30px;
                        span {
                            font-weight:600;
                            color:#999;
                        }
                    }
                }
                .product-info {
                    position:relative;
                    @include media($mobileL) {
                        padding-right: 125px;
                    }
                    @include media ($mobile) {
                        padding:0;
                        min-height:auto;
                        margin-bottom:25px;
                    }
                    .left {
                        float:left;
                        padding-right:25px;
                        width:calc(100% - 150px);
                        p {
                            @include font-size(14px);
                            margin:15px 0 0;
                            line-height:20px;
                        }
                    }
                    .right {
                        float:right;
                        width:150px;
                        img {
                            float:right;
                            width:100%;
                        }
                        a.download {
                            width:100%;
                            float:left;
                            background:$primary;
                            color:#FFF;
                            text-align:center;
                            padding:10px;
                            line-height:20px;
                            @include transition(.2s);
                            &:hover {
                                background:darken($primary, 10%);
                            }
                        }
                    }
                    h2 {
                        color:$secondary;
                        line-height:38px;
                        float:left;
                        width:100%;
                        margin:0;
                        @include font-size(30px);
                        @include media($mobileL) {
                            @include font-size(24px);
                            line-height:32px;
                            margin:0 0 5px;
                        }
                    }
                    span.sub-head {
                        float:left;
                        width:100%;
                        color:#666;
                        letter-spacing:1px;
                        @include font-size(13px);
                        line-height:20px;
                        margin:0 0 15px;
                        padding:0 0 15px;
                        border-bottom:1px solid #EEE;
                    }
                    p.product-code {
                        @include font-size(12px);
                        color:$primary;
                        font-weight:bold;
                        span {
                            font-weight:600;
                            color:#999;
                        }
                    }
                    a.brand-icon {
                        border:1px solid #EEE;
                        padding:5px;
                        background:#FFF;
                        margin:0 0 10px;
                        width:100%;
                        display:block;
                        overflow:hidden;
                        img {
                            float:left;
                            max-width:150px;
                            width:100%;
                            @include media($mobileL) {
                                width:125px;
                            }
                        }
                        @include media ($mobile) {
                            display:none;
                        }
                    }
                }
                .purchase-items {
                    float:left;
                    width:100%;
                    p {
                        line-height:22px;
                    }
                    @include media($mobile) {
                        margin-top:0;
                    }
                    h4 {
                        display:block;
                        float:left;
                        padding:15px;
                        border:1px dotted #DDD;
                        border-bottom:0px;
                        font-weight:bold;
                        background:#FFF;
                        @include font-size(13px);
                    }
                }
            }
            .product-tabs {
                width:100%;
                margin-top:25px;
                .tabs-menu {
                    width:100%;
                    float:left;
                    border-bottom:1px dotted #DDD;
                    li {
                        list-style: none;
                        float:left;
                        margin-right:10px;
                        @include media($mobile) {
                            width:100%;
                        }
                        a {
                            display:block;
                            background:#FFF;
                            padding:15px;
                            border:1px dotted #DDD;
                            font-weight:bold;
                            color:$primary;
                            border-bottom:0;
                            @include font-size(13px);
                        }
                        &.current {
                            a {
                                background:$primary;
                                color:#FFF;
                                pointer-events:none;
                                border:1px solid transparent;
                                border-bottom:0;
                                &:hover {
                                    background:$primary;
                                    color:#FFF;
                                }
                            }
                        }
                    }
                }
                .tab {
                    background:#F9F9F9;
                    float:left;
                    width:100%;
                    .tab-content {
                        display:none;
                        padding:25px;
                        float:left;
                        width:100%;
                        border:1px dotted #DDD;
                        background:#FFF;
                        h5 {
                            float:left;
                            width:100%;
                            padding:0 0 15px;
                            margin:10px 0 25px;
                            font-weight:bold;
                            color:#666;
                            border-bottom:1px solid #DDD;
                            @include font-size(13px);
                        }
                        p {
                            color:#666;
                            line-height:28px;
                            @include font-size(14px);
                        }
                        &:first-child {
                            display:block;
                        }
                        table {
                            width:100%;
                            @include font-size(13px);
                            tbody {
                                tr {
                                    td {
                                        padding: 10px 20px;
                                        border:1px solid #eee;
                                        text-align:center; 
                                        vertical-align:middle;
                                        &:first-child {
                                            background:#F8F8F8;
                                            font-weight:bold;
                                            width:250px;
                                        }
                                    }
                                }
                            }
                        }
                        table.rwd-table {
                          background:#FFF;
                          width:100%;
                          line-height:22px;
                          margin:0;
                          text-align:center;
                          @include font-size(14px);
                          thead {
                              background:#F8F8F8;
                              tr {
                                  th {
                                      border:1px solid #EEE;
                                      padding: 10px 15px;
                                      color:#777;
                                      font-weight:bold;
                                      text-align:center;
                                      vertical-align: middle;
                                      &:first-child {
                                          border-right:0px;
                                          @include media($mobile) {
                                              display:none;
                                          }
                                      }
                                  }
                              }
                          }
                          tbody {
                              tr {
                                  border-color:#FFF;
                                  td {
                                      border:1px solid #EEE;
                                      vertical-align: top;
                                      padding:10px;
                                      text-align:center;
                                      &:first-child {
                                        vertical-align: middle;
                                        width:120px;
                                      }
                                      @include media($mobile) {
                                          border:0;
                                          border-bottom:1px solid #EEE;
                                          &:first-child {
                                            width:100%;
                                          }
                                      }
                                      &:before {
                                          width: 100%;
                                          @include font-size(16px);
                                      }
                                      &.remove {
                                          padding:15px 25px;
                                          a {
                                              text-align:center;
                                              width:100%;
                                              color:$error;
                                              display:block;
                                              @include font-size(24px);
                                          }
                                          @include media($mobile) {
                                              padding:0;
                                              &:before {
                                                  display:none;
                                              }
                                              a {
                                                  border-bottom:5px solid #EEE;
                                                  display:block;
                                                  float:left;
                                                  width:100%;
                                                  background:$error;
                                                  color:#FFF;
                                                  text-align:center;
                                                  line-height:40px;
                                                  cursor:pointer;
                                              }
                                          }
                                      }
                                      &.price {
                                          padding:15px;
                                          p {
                                              color:$success;
                                              @include font-size(18px);
                                          }
                                          @include media($mobile) {
                                              text-align:center;
                                              float:left;
                                              width:100%;
                                              padding:15px;
                                          }
                                      }
                                      &.qty-wrap {
                                          width:152px;
                                          span.cart {
                                              display:block;
                                              padding:25px 15px;
                                              width:auto;
                                              text-align:center;
                                              float:left;
                                              color:$secondary;
                                          }
                                          .qty {
                                              padding:15px 25px;
                                              input {
                                                  display:inline-block;
                                                  height: 30px;
                                                  width: 30px;
                                                  text-align: center;
                                                  background:#FFF;
                                                  font-weight:bold;
                                                  color:#222;
                                                  border:1px solid #DDD;
                                                  @include transition(all .2s);
                                                  &:focus {
                                                      background:#EEE;
                                                  }
                                              }
                                              span {
                                                  display:inline-block;
                                                  width: 100%;
                                                  text-align: center;
                                                  height: 30px;
                                                  width: 30px;
                                                  line-height:30px;
                                                  background: #EEE;
                                                  color: #AAA;
                                                  cursor: pointer;
                                                  @include transition(.2s all);
                                                  &:hover {
                                                      color:#666;
                                                  }
                                                  i {
                                                      font-size: 12px;
                                                      line-height: 30px;
                                                  }
                                              }
                                          }
                                          @include media($mobile) {
                                              width:100%;
                                              float:left;
                                              padding:15px 15px 0;
                                              text-align:center;
                                              .qty {
                                                  padding: 10px 0 25px;
                                              }
                                          }
                                      }
                                      &.product-image {   
                                          width:90px;
                                          padding:15px;
                                          vertical-align: top;
                                          @include media($mobile) {
                                              display:none;
                                          }
                                      }
                                      &.product-info {
                                          padding:10px 15px;
                                          p.name {
                                              float:left;
                                              width:100%;
                                              a {
                                                  color:$secondary;
                                                  margin:0 0 5px;
                                                  font-weight:bold;
                                                  @include font-size(16px);
                                              }
                                          }
                                          p.sdesc {
                                              color:#999;
                                              font-weight:bold;
                                              float:left;
                                              margin:0;
                                              width:100%;
                                              line-height: 18px;
                                              @include font-size(12px);
                                              strong {
                                                  color:$primary;
                                              }
                                          }
                                          a {
                                              float:left;
                                              margin:10px 0 0;
                                          }
                                         @include media($mobile) {
                                              text-align:center;
                                              float:left;
                                              width:100%;
                                              padding:15px;
                                              p.name {
                                                  a {
                                                      text-align:center;
                                                      width:100%;
                                                      float:left;
                                                  }
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                        } 
                    }
                }
            }
            .related-products {
                float:left;
                width:100%;
                margin:50px 0 15px;
                h3 {
                    margin:0 0 25px;
                    padding:0 0 25px;
                    text-align:left;
                    float:left;
                    width:100%;
                    color:$primary;
                    font-weight:200;
                    border-bottom:1px solid #EEE;
                    position:relative;
                    @include font-size(24px);
                    @include media($mobile) {
                        margin:0 0 15px;
                        padding:0 0 15px;
                    }
                    a {
                        position:absolute;
                        top:-8px;
                        right:0;
                        @include font-size(16px);
                        color:$primary;
                        font-weight:600;
                        padding:15px;
                        line-height:14px;
                        @include transition(all .2s);
                        i {
                            float:right;
                            margin:-2px 0 0 10px;
                            @include font-size(18px);
                            line-height:14px;
                        }
                        &:hover {
                            color:$secondary
                        }
                        @include media($mobile) {
                            display:none;
                        }
                    }
                }
                ul.product-list {
                    li {
                        list-style: none;
                        @include span-columns(3);
                        @include media($mobile) {
                            @include span-columns(6);
                            margin-bottom:8px;
                            &:nth-child(even) {
                                margin-right:0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.product-options {
    width:100%;
    padding:20px;
    background:#FFF;
    border:1px dotted #DDD;
    .actions {
        float:left;
        width:100%;
        padding:15px 0 0;
        border-top:1px solid #EEE;
        a, button {
            width:100%;
        }
    }
    p {
        @include font-size(14px);
    }
    h5 {
        width:100%;
        padding:0 0 15px;
        margin:0 0 15px;
        font-weight:600;
        color:#666;
        border-bottom:1px solid #DDD;
        @include font-size(13px);
    }
    .colour-option {
        width:100%;
        margin:0 0 5px;
        h5 {
            color:$primary;
            @include font-size(16px);
            span {
                font-weight:bold;
            }
        }
        ul {
            display: flex;
            flex-wrap:wrap;
            margin:0 -5px;
            justify-content: flex-start;
            width:100%;
            li {
                list-style: none;
                width:20%;
                padding:0 5px;
                margin:0 0 15px;
                min-width: 75px;
                span {
                    display:block;
                    width:100%;
                    text-align:center;
                    font-weight:bold;
                    @include font-size(14px);
                    &.qty {
                        color:#999;
                        @include font-size(12px);
                    }
                }
                input[type="text"] {
                    width:100%;
                    border-radius:0;
                    border:1px solid #DDD;
                    box-shadow:none;
                    outline:none;
                    background:#FFF;
                    margin:5px 0 0;
                    &:disabled {
                        background:#EEE;
                    }
                    &:focus {
                        border-color:$secondary;
                    }
                }
                // &:nth-child(5n) {
                //     padding-right:0;
                // }
                // @include media($mobileL) {
                //     width:33.3333%;
                //     &:nth-child(5n) {
                //         padding-right:10px;
                //     }
                //     &:nth-child(3n) {
                //         padding-right:0;
                //     }
                // }
            }
        }
    }
    .checkbox-wrap, .size-input {
        width:44px;
        float:left;
        margin:0 15px 25px 0;
        border:2px solid #DDD;
        label.colour-box {
            width:40px;
            height:40px;
            float:left;
            border:5px solid #FFF;
            cursor:pointer;
        }
        &.checked {
            border:2px solid $primary;
        }
    }
    .size-input {
        border:0;
        width:16.666667%;
        margin:0;
        padding-right:2.5%;
        @include media($mobileL) {
            width:33.3333%;
            margin:0 0 10px;
            &:nth-child(7), &:nth-child(10), &:nth-child(13) {
                padding-right:0;
            }
        }
        label {
            margin:0 0 5px;
            font-weight:bold;
            text-align:center;
            width:100%;
            @include font-size(12px);
        }
        input {
            width:100%;
            text-align:center;
            height:40px;
            background:#FFF;
            border:1px solid #DDD;
        }
    }
    .add-zone {
        float:left;
        width:100%;
        padding:25px 0 0;
        border-top:1px dotted #DDD;
        margin:25px 0 0;
        button {
            float:left;
            width:200px;
            height:40px;
            text-align:center;
            color:#FFF;
            background:#9DCA50;
            font-weight:bold;
            border-radius:2px;
            border:0;
            @include font-size(14px);
            @include transition(all .2s);
            &:hover {
                background: $success;
            }
            &.inactive {
                pointer-events:none;
                background:$secondary;
            }
            @include media($mobile) {
                width:100%;
            }
        }
    }
}
