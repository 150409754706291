/* Global Styles */
body {
    background:#f9f9f9;
    color: $primary;
    font: 16px $ffamily;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-text-size-adjust: none;
}
:focus, a:focus, btn:focus, button:focus {
    border:none;
    box-shadow: none;
    outline: none;
    text-decoration: none;
}
.no-pointer {
    pointer-events: none;
}
a.btn:not([href]):not([tabindex]) {
    color:#fff;
}
.message-details {
    border-top:2px solid $primary;
    margin-top:122px;
}

/* TYPOGRAPHY */
h1,h2,h3,h4,h5 {
    color: $primary;
    font-weight: 400;
}
h1 {
    font-size: 1.3em;
    line-height: 120%;
    margin-bottom: 30px;
    text-align: center;
}
h2 {
    font-size: 1.625em;
    margin: 0 0 15px;
    &:first-of-type {
        margin-top: 0;
    }
}
h3 {
    font-size:1.75em;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom:15px;
    margin-top:15px;
    &:first-of-type {
        margin-top: 0;
    }
}
h4 {
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 30px;
}
h5 {
    color: #717171;
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.75;
}
h6 {
    font-size: 1em;
    font-weight: 700;
    line-height: 1.6;
}
p, li {
    font-size:1em;
    line-height: 1.4;
}
li {
    line-height: 1.75;
}
p {
    color:#666;
    font-size: 1em;
    margin-bottom: 15px;
    strong {
        color:$primary;
        font-weight: 700;
    }
}
strong {
    font-weight:900;
}
address {
    color:$primary;
    font-family: $heading;
    font-size: 1em;
    font-weight: 400;
    margin-bottom:0px;
    p {
        margin:0;
        font-weight: 400;
    }
    p strong {
        font-weight: 900;
    }
}
ul {
    margin-bottom: 15px;
    padding-left: 15px;
    text-align: left;
    li {
        list-style: disc;
        margin-bottom: 15px;
    }
    ul {
        margin-top:10px;
        margin-bottom: 5px;
        padding-left:30px;
        li {
            margin-bottom: 10px;
            &:before {
                background:url(./dist/images/sub-list-item.png) no-repeat center left;
            }
        }
    }
}
ol {
    margin-bottom: 15px;
    padding-left: 20px;
    li {
        list-style: decimal;
        margin-bottom: 10px;
    }
}

/* TABLES */
.table-group {
    table {
        margin:0;
        &:last-child {
            margin-bottom:30px;
        }
    }
    td.has-table {
        padding:0;
    }
}
table {
    border-top:1px solid #e1eff4;
    column-span:all;
    margin:30px 0;
    max-width:100%;
    width: 100%;
    table {
        margin:0;
    }
    td {
        border-right:1px solid #e1eff4;
        border-bottom:1px solid #e1eff4;
        padding:15px;
        &:first-child {
            border-left:1px solid #e1eff4;
        }
        &:hover {
            background:#f1f1f1;
        }
    }
    thead,th {
        background:#f8f8f8;
        border:none;
        color: #999;
        font-weight: 700;
        padding:15px;
    }
    tbody {
        tr {
            &:nth-child(2n) {
                background:#fafafa;
            }
        }
    }
    tfoot {
        td {
            background:$primary;
            border:none;
            color: rgba(255,255,255,0.6);
            font-weight: 700;
            font-size: 14px;
            padding:5px 15px;
        }
    }
    img, p {
        margin:0;
    }
    img {
        margin:0 auto;
        max-width: 75px;
        vertical-align: top;
    }
}

/* MODALS */
.modal {
    align-items: center;
    background:rgba(0,0,0,0.8);
    justify-content: center;
    z-index: 999999;
    .inner {
        background:#fff;
        max-width: 750px;
        padding:30px;
        width: 100%;
        h3 {
            margin-top: 0;
        }
        .close {
            background:none;
            box-shadow: none;
            font-size: 12px;
            text-transform: uppercase;
            transform: none;
            span {
                font-size: 18px;
            }
        }
    }
    &.active {
        display: flex;
        opacity: 1;
    }
    &.fade.in {
        display: flex!important;
    }
    &.newsletter {
        .inner {
            max-width: 550px;
        }
    }
}

/* GLOBAL ELEMENTS */
img, video {
    height: auto;
    max-width: 100%;
    &.bordered {
        border:1px solid #eee;
    }
}
iframe {
    height: auto;
    max-width:100%;
}
a {
    color: $secondary;
    cursor:pointer;
    text-decoration: none;
    &:hover {
        color: seagreen;
        text-decoration:none;
    }
    &:focus {
        color:seagreen;
    }
    &:link {
        cursor: pointer;
    }
}
.btn, button {
    background:$primary;
    border-radius:3px;
    border:none;
    color:#fff;
    font-family: $heading;
    font-weight: 400;
    font-size: 16px;
    margin: 15px 0;
    padding:15px 25px;
    position: relative;
    transition:all 0.3s;
    &:hover {
        background:darken($primary, 10%);
        color:#fff;
    }
    &:focus {
        color: #fff;
        outline: none;
    }
}
blockquote {
    border-left: 3px solid $primary;
    color:$primary;
    font-size: 1.625em;
    font-style: italic;
    font-weight: 600;
    margin: 0 0 20px;
    padding: 10px 40px;
    position: relative;
    quotes: "“" "”" "‘" "’";
    > *:last-child {
      margin-bottom: 0;
    }
    &:before, &:after {
        font-weight: 700;
        font-size: 2em;
        font-style: normal;
        line-height: 1;
        position: absolute;
    }
    &:before {
        content: open-quote;
        left:0;
        margin-top: -5px;
    }
    &:after {
        content: close-quote;
        margin-left:15px;
        margin-top: 3px;
    }
}
section, main {
    padding:30px 0;
    position:relative;
    z-index: 10;
}
#wrap {
    padding:0;
}
.center-block {
    float: none;
    margin:0 auto;
}
.title {
    margin-bottom: 40px;
    text-align: center;
}
.fl-right, .alignright, .right {
    float: right;
}
.fl-left, .alignleft, .left {
    float: left;
}
img.alignright, img.right {
    margin-left:15px;
}
img.alignleft, img.left {
    margin-right:15px;
}
.flex-container {
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.img-wrap {
    overflow:hidden;
    position:relative;
}

/* FORMS */
form {
    margin-bottom:30px;
}
input[type=text], input[type=email], textarea {
    border:1px solid #e3e3e3;
    border-radius:3px;
    color:#000;
    font-size: 0.75em;
    padding:10px;
    width: 100%;
    &:focus {
        border:1px solid $primary;
    }
}
textarea {
    min-height: 140px;
    max-width: 100%;
}
input[type=radio] {
    height: 16px;
    margin-top: 0;
    width: 18px;
}
input[type=radio] + label a {
    color: $primary;
}
input[type=file] {
    color:#000;
    font-size: 0.75em;
}
label {
    color:#000;
    display: inline-block;
    font-size: 0.875em;
    font-weight: 600;
    letter-spacing: 1px;
}
.input {
    display: inline-block;
    margin: 0 0 20px;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    width: 100%;
    z-index: 1;
}
.input-field {
    border: none;
    border-radius: 0;
    background: transparent;
    color: $primary;
    display: block;
    font-size: 1.25em;
    font-weight: 400;
    font-family: $heading;
    float: right;
    height: 50px;
    position: relative;
    padding: 0 0 0.25em;
    width: 100%;
    -webkit-appearance: none; /* for box shadows to show on iOS */
}
.input-field:focus {
    outline: none;
}
.input-label {
    color:$primary;
    display: block;
    float: right;
    font-weight: 700;
    font-family: $heading;
    font-size: 1em;
    left: 0;
    padding: 0em;
    position: absolute;
    pointer-events: none;
    text-align: left;
    top: 7px;
    width: 100%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: -webkit-transform 0.2s 0.15s, color 1s;
    transition: transform 0.2s 0.15s, color 1s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.input-label-content {
    position: relative;
    display: block;
    padding: 1.6em 0;
    width: 100%;
}
.graphic {
    position: absolute;
    top: 0;
    left: 0;
    fill: none;

    stroke: $primary;
    stroke-width:2px;
    pointer-events: none;
    -webkit-transition: -webkit-transform 0.7s, stroke 0.7s;
    transition: transform 0.7s, stroke 0.7s;
    -webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}
.icon {
    color: #fff;
    font-size: 150%;
}
.input-field:focus + .input-label,
.input-filled .input-label {
    color:$secondary;
    -webkit-transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
    transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
}
.input-filled .input-label {
    color:$primary;
}
.input-field:focus ~ .graphic,
.input-filled .graphic {
    stroke: $secondary;
    -webkit-transform: translate3d(-66.6%, 0, 0);
    transform: translate3d(-66.6%, 0, 0);
}
.input-filled .graphic {
    stroke:$primary;
}
.input.textarea {
    height: auto;
    .input-field {
        // font-size: 1em;
        line-height: 1.5;
        padding-top: 0.5em;
        // resize: none;
    }
    .input-label {
        height:140px;
        &:before {
            height:140px;
        }
    }
    .graphic {
        top:auto;
        bottom:0;
        max-height: 66px;
    }
}
.checkbox {
    cursor:pointer;
    margin:0;
    padding:0;
    position:relative;
    transition:0.2s;
    input {
        height:17px;
        opacity:0;
        width:17px;
    }
    input[type=checkbox] {
        padding:0;
    }
    label {
        cursor: pointer;
        font-size: 1em;
        letter-spacing: 0;
        margin:0;
        transition:0.2s;
        &:before {
            background:#344B63;
            border-radius:3px;
            content:'';
            height: 16px;
            left:1px;
            margin:0;
            position: absolute;
            top: 3px;
            transition: 0.2s;
            width: 16px;
        }
        &:after {
            background:url(./dist/images/check.svg) no-repeat center / contain;
            content:'';
            display: block;
            height:12px;
            left:1px;
            position:absolute;
            top:5px;
            transform: scale(0);
            transition: 0.2s;
            width:16px;
        }
        span {
            color:#000;
            margin-left:5px;
        }
    }
    input:checked + label:before {
        // box-shadow:0 0 0 1px #36eac2;
        // border-color:#36eac2;
    }
    input:checked + label:after {
        transform: scale(1);
    }
    &:hover {
        label {
            color: #282c37;
            &:before {
                border-color:#282c37;
            }
        }
    }
}
.required .input-label-content:after, .required label:after {
    content:' *';
    color:crimson;
    display: inline-block;
    margin-left:3px;
}


/* ALERTS */
.success {
    color: seagreen;
}
.pending {
    color: orange;
}
.error {
    color: crimson;
}
.btn.success {
    background: seagreen;
    color: #fff;
}
.btn.pending {
    background: orange;
    color: #fff;
}
.btn.error {
    background: crimson;
    color: #fff;
}
.alert {
    border:none;
    color: #fff;
    font-weight: 700;
    font-family: $heading;
    text-align: center;
    ul {
        display: inline-block;
        text-align: left;
        li {
            margin:5px 0;
        }
    }
}
.alert.success, .alert-success {
    background-color: $secondary;
    border-color: darken($secondary, 10%);
}
.alert.error, .alert-error {
    background-color: crimson;
}


.hide-me {
    display: block;
    height: 0%;
    overflow:hidden;
    transition:all 0.3s ease;
}
.active .hide-me {
    display: inline;
}

/* 404 */
.user-page {
    background: $secondary;
    margin-top:107px;
    padding:0;
    .container {
        align-items: center;
        display: flex;
        height:83vh;
        justify-content: center;
    }
    .inner {
        background:#fff;
        border-radius:3px;
        max-width: 100%;
        padding:30px;
        text-align: center;
        width:600px;
        @media(min-width:768px) {
            padding:60px;
        }
    }
    h1, h2, h3, p {
        color:$primary;
    }
    h1 {
        font-size: 5em;
        font-weight: 700;
        margin:0 0 30px;
        @media(min-width:768px) {
            // font-size: 10em;
        }
    }
    h2 {
        font-size: 4.8em;
        margin: 0;
        &:after {
            transform: translateY(-25px);
            margin-bottom: -25px;
        }
    }
    h3 {
        color:$primary;
        font-size: 0.875em;
        line-height: 1.6;
        margin-bottom: 15px;
        @media(min-width:768px) {
            font-size: 1.05em;
            margin-bottom:30px;
        }
    }
    .btn {
        margin:5px 0;
        width:100%;
        @media(min-width:768px) {
            margin:15px 7px;
            width:calc(50% - 17px);
        }
    }
}

.mobile-hide, .tablet-hide {
    display: none;
}
@media(min-width:768px) {
    .mobile-show {
        display: none;
    }
    .mobile-hide {
        display: block;
    }
}
@media(min-width:992px) {
    .tablet-hide {
        display: block;
    }
}



.inner-wrap {
    max-width:1200px;
    width:100%;
    margin:0 auto 0;
    overflow:hidden;
    padding-top:131px;
}

.fadeIn, .wf-active {
    @include fadeIn( .5s );
}

.wf-loading {
    opacity:0;
}

/* Ghost Button */
.gbtn {
    border:2px solid #FFF;
    padding:15px;
    background:transparent;
    text-align:center;
    color:#FFF;
    text-transform: uppercase;
    display:block;
    font-weight:bold;
    @include font-size(14px);
    @include transition(.2s all);
    &:hover {
        background:#FFF;
        color:$primary;
    }
    &.success {
      background:$success;
      &:hover {
        background:darken($success, 10%);
        color:#FFF;
      }
    }
}

/* Select Menu */
.sort-by, .select-wrap {
    select {
        width:100% !important;
    }
    .select2-container {
        width: 100% !important;
        height:48px;
        span.selection {
            .select2-selection {
                height:100%;
                border:1px solid #DDD;
                border-radius:0px;
                .select2-selection__rendered {
                    line-height:48px;
                    font-weight:600;
                    color:#333;
                    @include font-size(14px);
                }
                .select2-selection__arrow {
                    height:44px;
                    width:35px;
                    top:2px;
                    right:0;
                    &:before {
                      position:absolute;
                      top:2px;
                      right:0;
                      height:44px;
                      line-height:44px;
                      width:35px;
                      text-align:center;
                      @include font-size(12px);
                      color:#999;
                      content: "\f107";
                      font-family: FontAwesome;
                      font-style: normal;
                      font-weight: normal;
                      text-decoration: inherit;
                    }
                    b {
                      border:0;
                    }
                }
            }
            .select2-default {
                .select2-selection__rendered {
                    color:#EEE;
                }
            }
        }
        &:focus, &.select2-container--focus {
            * {
                outline:none;
                box-shadow:none;
            }
        }
    }
}

/* Alert Wrap */
.alert-wrap {
    float:left;
    width:100%;
    .alert {
        margin:15px 0;
        border-radius:0px;
        border:0;
        @include font-size(14px);
        line-height:24px;
        &.alert-success {
            background:$success;
            color:#FFF;
        }
        &.alert-warning {
            background:$cateringh;
            color:#FFF;
        }
        &.alert-info {
            background:$healthc;
            color:#FFF;
        }
        &.alert-danger {
            background:$error;
            color:#FFF;
        }
    }
}

.corner-ribbon{
  width: 185px;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 30px;
  letter-spacing: 1px;
  color: #FFF;
  text-transform: uppercase;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Custom styles */
.corner-ribbon.sticky{
  position: fixed;
}

.corner-ribbon.shadow{
  box-shadow: 0 0 3px rgba(0,0,0,.3);
}

/* Different positions */
.corner-ribbon.top-left{
  top: 25px;
  left: -50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.top-right{
  top: 25px;
  right: -50px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-left{
  top: auto;
  bottom: 25px;
  left: -50px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-right{
  top: auto;
  right: -50px;
  bottom: 25px;
  left: auto;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Colors */
.corner-ribbon.hot{background: $cateringh;}
.corner-ribbon.sale{background: $success;}
.corner-ribbon.new{background: $healthc;}

#quick-order {
  .actions {
    border-top:1px solid #EEE;
    padding-top:15px;
    float:left;
    width:100%;
  }
  .signup-wrap {
    padding:10px;
    border:1px solid #EEE;
    background:#FFF;
    float:left;
    margin:0 0 25px;
    input {
      border:1px solid #EEE;
      border-radius:0;
      padding:15px 10px;
      width:100%;
    }
  }
}

.table-wrap {
  overflow-y: hidden;
  overflow-x: scroll;
  margin: 0 0 15px;
  float: left;
  width: 100%;
  padding-bottom:10px;
  @include media($mobileL) {
    overflow:hidden;
  }
  table.rwd-table {
    margin:0 !important;
  }
}