ul.product-list {
    li {
        list-style: none;
        @include span-columns(2.4);
        margin-bottom:25px;
        background:#FFF;
        @include transition(all .2s);
        a {
            display: block;
            position:relative;
            width:100%;
            padding:15px;
            span.product-code {
                width:100%;
                text-align:center;
                background: rgba(255, 255, 255, .75);
                color:#333;
                text-transform: uppercase;
                font-weight:bold;
                padding:10px 0;
                z-index:99;
                margin-top:-15px;
                @include font-size(12px);
                @include media($mobileL) {
                    @include font-size(11px);
                    span {
                        display:none;
                    }
                }
            }
            .product-info {
                position:relative;
                width:100%;
                width:100%;
                h4 {
                    padding:10px 0 0;
                    text-align:left;
                    color:$primary;
                    font-weight:bold;
                    min-height:55px;
                    line-height:22px;
                    text-transform: capitalize;
                    border-top:1px solid #DDD;
                    @include font-size(14px);
                    @include transition(all .2s);
                }
            }
            img {
                width:100%;
                padding:0 0 15px;
            }
            &:hover {
                .product-info {

                }
            }
        }
        &:nth-child(5n) {
            margin-right:0;
        }
        &:hover {
            border-color:$primary;
        }
    }
}

.zoomContainer {
    pointer-events:none;
}