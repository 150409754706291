/* Set REM Fontsize with Pixel fallback @include font-size(14px) */
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

/* Clearfix @include clearfix; */
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/* Vertically Center Div  @include vertical-align(); */
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

$prefix:'-moz-', '-webkit-', '-o-', '-ms-', '';

@mixin keyframe-fadeIn {
  0%   { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@-webkit-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@-o-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@-ms-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@keyframes fadeIn {
  @include keyframe-fadeIn;
}
@mixin fadeIn( $arg ) {
  $keyframe-name:fadeIn;
  $duration:$arg;
  @each $p in $prefix {
    #{$p}animation:$keyframe-name $duration;
  }
}
// e.g. @include fadeIn( 2s );
