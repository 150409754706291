#wrap.login-signup {
    width:100%;
    .inner-wrap {
        max-width:1200px;
        margin: 110px auto 0;
        padding:75px 35px;
        border-top:2px solid #FFF;
        @include media($tablet) {
            margin:60px auto 0;
        }
        .tabs-menu {
            width:750px;
            margin:0 auto;
            background:#FFF;
            display: flex;
            max-width:100%;
            padding:0;
            li {
                list-style: none;
                width:45%;
                @include transition(.2s all);
                a {
                    font-weight:600;
                    color:#2A3140;
                    text-align: left;
                    border: 1px solid #DDD;
                    padding: 20px 25px;
                    background: #FFF;
                    margin:0;
                    display:block;
                    width:100%;
                    @include font-size(18px);
                    &:hover {
                        background:#EEE;
                    }
                }
                &.current {
                    width:55%;
                    a {
                        border-color:$secondary;
                        background:$secondary;
                        color:#FFF;
                    }
                }
                &:first-child {
                    a {
                        border-right:0;
                    }
                }
                @include media($mobile) {
                    width:100%;
                    float:left;
                    a {
                        border:1px solid #EEE !important;
                        text-align:center;
                        margin:0 0 10px;
                    }
                    &:first-child, &.current {
                        width:100%;
                        float:left;
                    }
                }
            }
        }
        .login-wrap, .signup-wrap {
            margin:0 auto;
            overflow:hidden;
            display:none;
            background:#FFF;
            max-width:100%;
            form {
                width:100%;
                border:1px solid #EEE;
                overflow:hidden;
                .rememberme {
                    text-align:left;
                    float:right;
                    width:auto;
                    padding:19px;
                    label {
                        color:#666;
                        text-transform: uppercase;
                        @include font-size(12px);
                    }
                    input {
                        margin-left:5px;
                    }
                    * {
                        display:inline-block;
                    }
                }
                .form-wrap, .form-wrap-register {
                    width:100%;
                    float:left;
                    padding:0;
                    margin-top:-1px;
                    label {
                        width:100%;
                        float:left;
                        color:#333;
                        margin:0 0 5px;
                    }
                    label.error {
                        display:none !important;
                    }
                    input {
                        width:100%;
                        text-indent:10px;
                        line-height:45px;
                        border:1px solid #DDD;
                        border-radius:3px 3px 0 0;
                        font-size: 1em;
                        padding:3px;
                        @include transition(all .2s);
                        &:focus {
                            background: #EEE;
                        }
                        &.error {
                            color:$error;
                            border-color:$error;
                            background: rgba($error, .5);
                            @include placeholder {
                                color:rgba(#FFF, .7);
                            }
                        }
                    }
                    input[type="submit"] {
                        display:block;
                        background:$success;
                        text-transform: uppercase;
                        border-radius:3px;
                        color:#FFF;
                        border:0;
                        margin-top:15px;
                        text-indent:0;
                        font-family: $heading;
                        @include font-size(14px);
                        @include transition(all .2s);
                        &:hover {
                            background:darken($success, 10%);
                            border:0;
                        }
                    }
                    &.password {
                        input {
                            border-radius:0 0 3px 3px;
                        }
                    }
                    &.form-wrap-register {
                        width:50%;
                        padding:0 10px;
                        height:68px;
                        input {
                            border-radius:0;
                        }
                        @include media($mobile) {
                            width:100%;
                            margin:0 0 15px;
                        }
                    }
                    &.error {
                        label.error {
                            display:none !important;
                        }
                      .select2-container span.selection .select2-selection, .sort-by .select2-container span.selection .select2-selection {
                        color:rgba(#FFF, .7);
                        border-color:$error;
                        background: rgba($error, .5);
                      }
                      .select2-container--default .select2-selection--single .select2-selection__placeholder {
                        color:rgba(#FFF, .7);
                      }
                      .select2-container span.selection .select2-selection .select2-selection__arrow b, .sort-by .select2-container span.selection .select2-selection .select2-selection__arrow b {
                        border-color: rgba(#FFF, .7) transparent transparent;
                      }
                      input {
                        color:$error;
                        border-color:$error;
                        background: rgba($error, .5);
                        @include placeholder {
                          color:rgba(#FFF, .7);
                        }
                      }
                    }
                }
                .submit-zone {
                    margin:15px auto 0;
                    overflow:hidden;
                    height:auto;
                    border-top:1px solid #EEE;
                    width: calc(100% - 20px);
                    padding:15px 0 0;
                    text-align:right;
                    input[type="submit"] {
                        display:inline-block;
                        background:$success;
                        text-transform: uppercase;
                        border-radius:3px;
                        color:#FFF;
                        border:0;
                        line-height:50px;
                        float:right;
                        padding: 0 35px;
                        text-indent:0;
                        font-family: $heading;
                        @include font-size(14px);
                        @include transition(all .2s);
                        &:hover {
                            background:darken($success, 10%);
                            border:0;
                        }
                    }
                    a {
                        float:left;
                        color:#2A3140;
                        line-height:50px;
                        @include transition(all .2s);
                        &:hover {
                            color:$success;
                        }
                    }
                     @include media($mobile) {
                        width:100%;
                        margin:0;
                        text-align:center;
                        input[type="submit"] {
                            float:left;
                            width:100%;
                        }
                        a {
                            width:100%;
                            text-align:center;
                            line-height:20px;
                            margin-top:10px
                        }
                    }
                }
            }
        }
        .signup-wrap {
            width:750px;
            h1 {
                text-align:left;
                border:1px solid #DDD;
                padding:25px;
                background:#F8F8F8;
                margin:0;
            }
            form {
                border:1px solid #EEE;
                border-top:0px;
                padding:25px;
                overflow:hidden;
                @include media($mobile) {
                    border-top:1px solid #EEE;
                }
            }
            .form-wrap-register {
                margin:0 0 20px;
                label {
                    span {
                        color:$error;
                    }
                }
                input[type="submit"] {
                    margin-top:0 !important;
                }
            }
        }
    }
}