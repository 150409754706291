#wrap.resources {
    .resource-wrap {
        margin:75px 0;
        overflow:hidden;
        padding:0 25px;
        @include font-size(14px);
        @include media($mobile) {
            margin:50px 0;
        }
        .heading-zone {
            float:left;
            width:100%;
            padding:0 0 25px;
            h1 {
                line-height:32px;
                margin:0 0 10px;
                color:$secondary;
                @include font-size(32px);
                @include media($mobile) {
                    @include font-size(26px);
                }
            }
            p {
                color:#777;
                line-height:26px;
                @include font-size(16px);
            }
        }
        .filter-section {
            width:100%;
            background:#FFF;
            float:left;
            border:1px solid #EEE;
            margin:0 0 25px;
            p {
                float:left;
                line-height:45px;
                margin-left:15px;
                strong {
                    color:$secondary;
                }
                @include media($mobile) {
                    float:left;
                    margin:0;
                    padding:0 10px;
                    width:100%;
                    border-bottom:1px solid #EEE;
                }
            }
            .search-section {
                float:right;
                width:275px;
                position:relative;
                input {
                    width:100%;
                    height:45px;
                    border:0;
                    border-left:1px solid #EEE;
                    text-indent:5px;
                    background:#FFF;
                    @include transition(.2s all);
                    &:hover {
                        background:#F9F9F9;
                    }
                }
                button {
                    position:absolute;
                    top:0;
                    right:0;
                    height:45px;
                    width:45px;
                    z-index:1;
                    background:none;
                    border:0;
                    border-left:1px solid #EEE;
                    background:#FFF;
                    @include transition(.2s all);
                    &:hover {
                        color:$secondary;
                    }
                }
                @include media($mobile) {
                    width:100%;
                    float:left;
                    input {
                        border-left:0px;
                        text-indent:10px;
                    }
                }
            }
        }
        .resource-list {
            float:left;
            width:100%;
            margin:0 0 25px;
            ul {
                li {
                    padding:15px;
                    background:#FFF;
                    border:1px solid #EEE;
                    overflow:hidden;
                    list-style: none;
                    margin:0 0 10px;
                    position:relative;
                    @include clearfix;
                    .resource-name {
                        float:left;
                        width:100%;
                        padding-right:325px;
                        h3 {
                            @include font-size(20px);
                            margin:0 0 3px;
                            line-height:30px;
                            color:#555;
                        }
                        p {
                            line-height:24px;
                            color:#999;
                        }
                        @include media($tablet) {
                            width:100%;
                            float:left;
                            padding:0;
                        }
                    }
                    .download-info {
                        position:absolute;
                        top:15px;
                        right:15px;
                        a {
                            float:right;
                            padding:0 15px;
                            background:$primary;
                            line-height:45px;
                            color:#FFF;
                            border-radius:2px;
                            @include transition(.2s all);
                            i {
                                margin-left:15px;
                            }
                            &:hover {
                                background:darken($primary, 5%);
                            }
                        }
                        span {
                            float:right;
                            margin-right:25px;
                            line-height:45px;
                        }
                        @include media($tablet) {
                            width: 100%;
                            float: left;
                            position: relative;
                            top: 0;
                            left: 0;
                            padding: 0;
                            margin: 15px 0 0;
                            a {
                                float:left;
                                @include media($mobile) {
                                    width:100%;
                                    i {
                                        float:right;
                                        line-height:45px;
                                    }
                                }
                            }
                            span {
                                float:left;
                                position:absolute;
                                bottom:0;
                                right:0;
                                line-height:14px;
                                margin:0;
                                @include media($mobile) {
                                    display:none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}