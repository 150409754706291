#wrap.blog {
    width:100%;
    float:left;
    
    .post-head {
        position:relative;
        float:left;
        width:100%;
        &:before {
            content:"";
            position:absolute;
            bottom:0;
            left:0;
            height:75px;
            width:100%;
            z-index:4;
            opacity:0.5;
            background-image: linear-gradient(0deg, #000000 0%, rgba(0,0,0,0.00) 100%);
        }
        &:after {
            content:"";
            position:absolute;
            top:0;
            left:0;
            height:100%;
            width:100%;
            z-index:4;
            opacity:0.4;
            background:#000;
        }
        img {
            width:100%;
            float:left;
        }
        .post-info {
            @include vertical-align();
            position:absolute;
            width:100%;
            text-align:center;
            z-index:5;
            h1 {
                margin:0 0 15px;
                padding:0 125px;
                line-height:40px;
                text-align:center;
                color:#FFF;
                @include font-size(32px);
            }
            span {
                display:inline-block;
                margin:0 10px;
                color:#FFF;
                opacity:.8;
            }
        }
    }
    .inner-wrap {
        max-width:1200px;
        margin:50px auto 0;
        padding:0 35px;
        border-top:2px solid #FFF;
        .blog-inner-wrap {
            float:left;
            width:100%;
            .blog-content {
                background:#FFF;
                width:100%;
                max-width:1000px;
                margin:0 auto;
                overflow:hidden;
                padding:50px 25px;
                @include clearfix;
                .video-block {
                    float:left;
                    width:100%;
                    margin:25px 0;
                    a {
                        position:relative;
                        float:left;
                        width:100%;
                        border:2px solid transparent;
                        @include transition(all .2s);
                        span {
                            position:absolute;
                            bottom:0;
                            left:0;
                            padding:15px;
                            color:#FFF;
                            font-weight:bold;
                            background:$primary;
                            @include transition(all .2s);
                            i {
                                float:left;
                                margin-right:10px;
                            }
                        }
                        img {
                            float:left;
                            width:100%;
                        }
                        &:hover {
                            border:2px solid $primary;
                            span {
                                background: darken($primary, 5%);
                            }
                        }
                        &:focus {
                            outline:none;
                        }
                    }
                }
                .related-posts {
                    float:left;
                    width:100%;
                    padding-top:25px;
                    border-top:1px dashed #DDD;
                    h3 {
                        margin:0 0 35px;
                        padding:15px 0 0;
                        font-weight:normal;
                        color:#333;
                        line-height:26px;
                        @include font-size(26px);
                    }
                    ul {
                        margin:0;
                        li {
                            list-style-type:none;
                            margin:0 0 25px;
                            a {
                                float:left;
                                width:100%;
                                padding:0 0 25px;
                                line-height:32px;
                                font-weight:200;
                                color:$primary;
                                border-bottom:1px solid #DDD;
                                @include font-size(20px);
                                @include transition(all .2s);
                                span {
                                    float:left;
                                    width:100%;
                                    color:#666;
                                    @include font-size(14px);
                                }
                                &:hover {
                                    border-color:$primary;
                                }
                            }
                        }
                    }
                }
                .back-zone {
                    float:left;
                    width:100%;
                    margin:25px 0 0;
                    a {
                        display:block;
                        padding:15px;
                        background: $secondary;
                        border-radius:3px;
                        float:left;
                        color:#FFF;
                        font-weight:bold;
                        border:2px solid $secondary;
                        @include transition(all .2s);
                        i {
                            margin-right:5px;
                        }
                        &:hover {
                            color:$secondary;
                            background:#FFF;
                        }
                    }
                }
                p {
                    margin:0 0 25px;
                    color:#666;
                    line-height: 26px;
                    width:100%;
                    float:left;
                    font-weight:normal;
                    @include font-size(14px);
                    &:first-of-type {
                        line-height:28px;
                        @include font-size(16px);
                    }
                }
                a {
                    color:$primary;
                    &:hover {
                        color: darken($primary, 10%);
                    }
                }
                strong, b {
                    font-weight:bold;
                    color:#333;
                }
                ul, ol {
                    float:left;
                    width:100%;
                    margin:0 0 25px 25px;
                    li {
                        list-style-type: disc;
                        margin:0 0 5px;
                        color:#666;
                        line-height: 30px;
                        width:100%;
                        float:left;
                        @include font-size(16px);
                    }
                }
                h1, h2, h3, h4, h5 {
                    float:left;
                    color:$secondary;
                    font-weight:bold;
                    margin:0 0 15px;
                    width:100%;
                }
                h1 {
                    @include font-size(28px);
                    line-height:32px;
                }
                h2 {
                    @include font-size(24px);
                }
                h3 {
                    @include font-size(22px);
                }
                h4 {
                    @include font-size(20px);
                }
                h5 {
                    @include font-size(18px);
                }
                blockquote {
                    margin: 0 auto 35px;
                    font-style: italic;
                    line-height: 41px;
                    text-align: left;
                    float: left;
                    width: 100%;
                    padding: 0 0 0 25px;
                    border-left:2px solid #666;
                    @include font-size(24px);
                    p {
                        margin:0;
                    }
                }
                blockquote.overview {
                    color:$primary;
                    cite {
                        float:left;
                        width:100%;
                        color:#666;
                        text-align:center;
                        @include font-size(16px);
                    }
                }
            }
        }
        .blog-wrap {
            margin-top:30px;
            float:left;
            width:100%;
            .blog-listing {
                width:100%;
                float:left;
                article {
                    background:#F8F8F8;
                    @include span-columns(4);
                    margin-bottom:25px;
                    padding:25px;
                    border:1px solid #DDD;
                    a {
                        float:left;
                        overflow:hidden;
                    }
                    .img-wrap {
                        float:left;
                        width:100%;
                        margin:0 0 15px;
                        img {
                            width:100%;
                        }
                    }
                    h3 {
                        width:100%;
                        float:left;
                        color:#2A3140;
                        margin:0 0 15px;
                        line-height:26px;
                        font-weight:700;
                        @include font-size(18px);
                    }
                    span {
                        color:#999;
                        width:100%;
                        float:left;
                        margin:0 0 15px;
                        font-weight:600;
                        display:block;
                        @include font-size(12px);
                    }
                    p {
                        color:#666;
                        width:100%;
                        float:left;
                        line-height:22px;
                        font-weight:normal;
                    }
                }
            }
        }
        .top-bar {
            width:100%;
            float:left;
            padding:0 0 25px;
            margin:0 0 25px;
            border-bottom:1px dashed #DDD;
            .sidebar-widget {
                ul {
                    float:right;
                    li {
                        list-style: none;
                        display:inline-block;
                        margin-left:5px;
                        a {
                            display:block;
                            color:#666;
                            border:1px solid #DDD;
                            padding:15px;
                            @include transition(all .2s);
                            span {
                                float:right;
                                @include transition(all .2s);
                            }
                            &:hover {
                                background:#F8F8F8;
                                color:#333;
                                span {
                                    color:$secondary;
                                }
                            }
                        }
                    }
                }
                h2 {
                    font-weight:600;
                    line-height:46px;
                    color:#2A3140;
                    float:left;
                    @include font-size(18px);
                }
            }
        }
    }
}