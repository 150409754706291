
.fw-breaker {
    background:$primary;
    padding:60px 30px;
    text-align:center;
    h3 {
        color:#fff;
        font-size:3em;
    }
}

.center-col {
    padding:60px 0;
    .inner {
        margin:0 auto;
        max-width: 100%;
        width:800px;
    }
    @media(min-width:992px) {
        background:#fff;
        border: 1px solid #eee;
    }
}

.fw-image {
    text-align: center;
    img {
        max-width: 1200px;
        width:100%;
    }
}

.standard {
    background:#fff;
    border:1px solid #eee;
    margin:10px 0;
    padding:60px 30px;
    h2 {
        font-size: 2em;
        margin:30px 0;
    }
    h2, h3  {
        font-weight: 600;
        text-align: center;
    }
    .has-image {
        align-items:center;
        display: flex;
        margin:0 auto 30px;
        max-width: 800px;
        img {
            margin-right: 15px;
            max-width: 50px;
        }
        p {
            margin:0;
        }
    }
    .flex-grid {
        display: flex;
        flex-wrap:wrap;
        .item {
            padding:30px;
            text-align: center;
            width:50%;
            h4 {
                margin:0;
            }
            img {
                max-width: 50px;
            }
            @media(min-width:768px) {
                width:33.333%;
            }
            @media(min-width:992px) {
                width:25%;
            }
        }
        + p {
            margin:0 auto;
            max-width: 800px;
        }
    }
    &.bs {
        .row.flex {
            display: flex;
            flex-wrap:wrap;
            justify-content: center;
            &:before, &:after {
                display: none;
            }
        }
        .head {
            align-items:center;
            display: flex;
            margin-bottom: 15px;
            img {
                max-height:70px;
            }
        }
        h4 {
            margin:0;
            padding-left:15px;
        }
        .col-xs-12 {
            margin-bottom:30px;
        }
    }
}

.sbs-fw {
    background-repeat:no-repeat;
    background-position:top left;
    background-size:contain;
    background-color:rgba(255,255,255,0.9);
    background-blend-mode:soft-light;
    padding:60px 0;
    .container {
        display: flex;
        flex-direction: row-reverse;
    }
    .caption {
        max-width: 100%;
        width:720px;
        h2 {
            font-size: 2.5em;
            font-weight: 600;
        }
        h3 {
            font-weight: 600;
        }
    }
    blockquote {
        border-left:none;
        font-size: 1em;
        padding:20px 0;
        text-align: center;
        &:before, &:after {
            display: none;
        }
        h3 {
            font-weight: 600;
        }
    }
    &:nth-child(2n) {
        background-position:top right;
        .container {
            flex-direction: row;
        }
    }
    &.blue {
        background-color:rgba(0,173,238,0.9);
        h2, h3, p, li {
            color:#fff;
        }
        li {
            font-size:1.125em;
            font-weight:600;
        }
    }
    @media(min-width:1200px) {
        background-position:left;
        background-blend-mode:unset;
        background-size:35%;
        &:nth-child(2n) {
            background-position:right;
        }
    }
    @media(min-width:1500px) {
        background-size:contain;
    }

}

.sbs {
    // align-items:center;
    width:100%;
    display:flex;
    flex-wrap: wrap;
    margin:5px 0;
    border:1px solid #EEE;
    padding:20px;
    background:#FFF;
    &__image {
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        min-height: 120px;
        padding:30px;
        width:100%;
        img {
            width:100%;
        }
    }
    &__text {
        padding:30px;
        width:100%;
    }
    &:nth-child(2n) {
        flex-direction:row-reverse;
    }
    @media(min-width:768px) {
        flex-wrap:nowrap;
        &__image {
            width:50%;
        }
        &__text {
            width:50%;
        }
    }
}

    .banner--cms {
        margin:130px 0 0px;
        width:100%;
        height:300px;
        overflow:hidden;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-repeat:no-repeat;
        background-position:center;
        position:relative;
        &:before {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            content:'';
            background:rgba(#000, .4);
        }
        .heading-zone {
            left:50%;
            max-width:1000px;
            padding:0 25px;
            position:absolute;
            text-align:center;
            transform:translate3d(-50%,-50%,0);
            top:50%;
            width:100%;
            h1 {
                color:#FFF;
                font-weight:400;
                margin:0 0 15px;
                @include font-size(36px);
                @include media($mobile) {
                    margin:0;
                }
            }
            p {
                color:#EEE;
                padding:0 5%;
                line-height:24px;
                width:100%;
                text-align:center;
                @include font-size(16px);
            }
            a {
                width: auto;
                margin: 0 auto;
                border-color: #9ccc46;
                margin: 25px 0 0;
                display: inline-block;
            }
        }
        @include media($tablet) {
            margin-top:60px;
            p {
                @include media($mobile) {
                    display:none;
                }
            }
        }
        @include media($mobile) {
            height:300px;
        }
    }

    .nobanner-heading {
        padding:75px 0;
        float:left;
        width:100%;
        text-align:center;
        h1 {
            @include font-size(32px);
            font-weight:600;
            color:$primary;
            margin:0 0 10px;
        }
        p {
            @include font-size(14px);
            color:#666;
            width:100%;
            padding:0 20%;
            display:inline-block;
            line-height: 22px;
            @include media($tablet) {
                padding: 0 10%;
            }
        }
        a {
            display:inline-block;
            margin-top:15px;
        }
    }

    .cms__wrap__body, .textzone {
        width:100%;
        overflow:hidden;
        .text-block {
            float:left;
            width:100%;
            margin:0 0 25px;
            p {
                width:initial;
                float:none;
            }
            img {
                margin: 0 0 25px 25px;
            }
            &:nth-of-type(even) {
                img {
                    
                    margin: 0 25px 25px 0;
                }
                p {
                    text-align:right;
                }
            }
        }
        p {
            margin:0 0 15px;
            color:#666;
            line-height: 26px;
            width:100%;
            @include font-size(14px);
            &:first-child {
                font-weight:bold;
                line-height:30px;
                font-size:16px;
                margin:0 0 25px;
                em, a {
                    font-weight:600;
                    font-size:16px;
                }
            }
        }
        a {
            color:$secondary;
            &:hover {
                color: darken($primary, 10%);
            }
        }
        strong, b {
            font-weight:bold;
            color:#666;
        }
        ul, ol {
            float:left;
            width:100%;
            margin:0 0 25px 25px;
            li {
                list-style-type: disc;
                margin:0 0 5px;
                color:#666;
                line-height: 30px;
                width:100%;
                float:left;
                font-size:16px;
            }
        }
        em {
            font-style:italic;
        }
        h1, h2, h3, h4, h5 {
            float:left;
            color:$primary;
            font-weight:600;
            margin:0 0 25px;
            width:100%;
            letter-spacing:1px;
        }
        h1 {
            font-size:32px;
            line-height:48px;
        }
        h2 {
            font-size:32px;
        }
        h3 {
            font-size:28px;
        }
        h4 {
            font-size:24px;
        }
        h5 {
            font-size:22px;
        }
        blockquote {
            margin: 0 auto 35px;
            font-style: italic;
            line-height: 41px;
            text-align: left;
            float: left;
            width: 100%;
            padding: 0 50px;
            font-size:24px;
            border-left:5px solid #EEE;
            p {
                margin:0 !important;
            }
        }
    }

    .cms {
        padding:30px 0;
        max-width: 1200px;
        margin: 0 auto 0;
        overflow: hidden;
        text-align:left;
        &--nobanner {
            @include media($tablet) {
                margin:61px auto 0;
            }
        }
        &__wrap {
            width:100%;
            margin:0 0 25px;
        }
        &.centralised {
            text-align:center;
            ul, ol {
                margin:0 0 25px;
                padding:0;
                li {
                    list-style-type: none;
                }
            }
        }
    }

    .image-list {
        float:left;
        width:100%;
        ul {
            text-align:center;
            width:100%;
            float:left;
            li {
                display:inline-block;
                margin:10px;
                padding:10px;
                background:#FFF;
                border:1px solid #EEE;
                @include media($tabletP) {
                    margin:0 0 15px;
                    max-width: 100%;
                }
            }
        }
    }


.cms-page {
    @include clearfix;
    #contact-form {
        padding:25px;
        border:1px solid #EEE;
        background:#FFF;
        float:left;
        width:100%;
        .actions {
            width:calc(100% - 20px);
            margin:5px 10px 0;
            padding:15px 0 0;
            border-top:1px solid #EEE;
            float:left;
        }
        .form-wrap {
            width:33.3333%;
            padding:0 10px;
            float:left;
            margin:0 0 15px;
            &.bigger {
                width:100%;
                float:left;
            }
            label {
                margin:0 0 5px;
                float:left;
                width:100%;
            }
            input {
                border:1px solid #EEE;
                float:left;
                width:100%;
                height:40px;
                text-indent:8px;
                &:focus {
                    border-color:$secondary;
                }
            }
            select {
                width:100%;
                float:left;
            }
            textarea {
                float:left;
                width:100%;
                border:1px solid #EEE;
                height:150px;
                resize:none;
                padding:8px;
                &:focus {
                    border-color:$secondary;
                }
            }
            .select2-container {
                *:focus {
                    outline:none;
                }
            }
            .select2-selection, .select2-selection--single {
                border:1px solid #EEE;
                border-radius:0;
                height:41px;
                .select2-selection__rendered {
                    @include font-size(14px);
                    line-height:41px;
                }
            }
            .select2-container--open {
                .select2-selection, .select2-selection--single {
                    border-color:$secondary;
                }
            }
            .select2-container--default .select2-selection--single .select2-selection__arrow b {
                margin-top:5px;
                margin-left:-8px;
            }
        }
    }
    .staff {
        margin:50px 0 0;
        text-align:center;
        float:left;
        width:100%;

        h3 {
            @include font-size(26px);
            margin:0 0 20px;
            color:$primary;

        }

        &__member {
            display:inline-block;
            padding:0 5px;
            width:calc(25% - 5px);
            text-align:left;
            margin:0 0 10px;
            &__wrap {
                border:1px solid #EEE;
                background: #FFF;
                padding:15px;
                float:left;
                width:100%;
            }
            img {
                width:100%;
                margin:0 0 20px;
            }
            h5 {
                @include font-size(16px);
                margin:0 0 10px;
                font-weight:600;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow:hidden;
            }
            p {
                font-weight:600;
                line-height: 20px;
                color:#666;
                @include font-size(12px);
                a {
                    @include font-size(12px);
                    margin-top:10px;
                    color:$secondary;
                    @include transition(.2s);
                    &:hover {
                        color:$primary;
                    }
                }
            }
            @include media($tablet) {
                width:33.3333%;
            }

            @include media($mobileL) {
                width:50%;
            }

            @include media($mobile) {
                width:100%;
            }
        }
    }
    .post-info {
        width:100%;
        z-index:5;
        max-width:1030px;
        margin:80px auto 25px;
        padding:0 15px 0;
        overflow:hidden;
        h1 {
            margin:0 0 10px;
            line-height:40px;
            text-align:left;
            color:$primary;
            font-weight:600;
            @include font-size(32px);
        }
        span {
            color:#666;
            opacity:.8;
            border-bottom:1px solid #EEE;
            width:100%;
            padding:0 0 25px;
            float:left;
            @include font-size(16px);
        }
    }
    .content {
        max-width:1030px;
        margin:0 auto;
        width:100%;
        padding:0 15px;
        overflow:hidden;
        @include media($mobileL) {
            margin:60px auto 0;
        }
        .post-body, .post__body {
            h1,h2,h3,h4,h5,h6 {
                font-weight:bold;
                color:$primary;
            }
            h1 {
                @include font-size(40px);
            }

            h2 {
                @include font-size(32px);
            }

            h3 {
                @include font-size(26px);
            }

            h4 {
                @include font-size(20px);
            }

            h4.widget-heading {
                font-weight:400;
                @include font-size(18px);
                margin:0 0 10px;
                cursor:pointer;
                padding:10px 15px;
                border-bottom:1px solid #EEE;
                i {
                    @include font-size(16px);
                    @include transition(.2s);
                    float:right;
                    line-height:28px;
                }
            }

            h5 {
                @include font-size(16px);
            }

            h6 {
                @include font-size(14px);
            }
            h1, h2, h3 ,h4 ,h5 {
                margin:0 0 15px;
            }
            p {
                line-height:26px;
                margin:0 0 25px;
                @include font-size(16px);
            }
            ul, ol {
                float:left;
                width:100%;
                margin:0 0 25px;
            }
            li {
                margin:0 0 5px 25px;
                padding-left:5px;
                &:last-child {
                    margin-bottom:0;
                }
            }
            ul li {
                list-style-type: disc;
            }
            ol li {
                list-style-type: decimal;
            }
            blockquote {
                margin:0 0 25px;
                float:left;
                width:100%;
                padding:25px;
                border:1px solid #EEE;
                line-height:28px;
                @include font-size(16px);
                border-radius:3px;
                background:#FFF;
                &:before {
                    content:"“";
                }
                &:after {
                    content:"”";
                }
                &:after, &:before {
                    font-weight:bold;
                    @include font-size(16px);
                }
            }
            .table-wrap {
                float:left;
                width:100%;
                overflow-x: auto;
            }
            table {
                min-width:100%;
                border:1px solid #EEE;
                border-right: 0;
                margin:0 0 25px;
                background:#FFF;
                th, td {
                    padding:10px 15px;
                    text-align:left;
                    border-right:1px solid #EEE;
                    border-bottom:1px solid #EEE;
                }
                th {
                    font-weight:600;
                    color:$secondary;
                }
                td {
                    color:#AAA;
                }
            }
        }
    }
    .fabrication {
        img.wide {
            padding:25px;
            border:1px solid #EEE;
            margin:5px 0 15px 15px;
            float:right;
            width:575px;
            max-width:100%;
            background:#FFF;
            border-radius:3px;
        }
        p, ul {
            width:inherit;
            float:none;
        }
        .actions {
            float:left;
            width:100%;
            padding-top:15px;
            border-top:1px solid #EEE;
            margin:0 0 50px;
            a {
                float:left;
                margin:0;
            }
            @include media( mobileL) {
                margin:0 0 25px;
            }
        }
        .other {
            float:right;
            width:100%;
            padding:25px 0;
            border-top:1px solid #EEE;
            margin-top:25px;
            .text-zone {
                float:left;
                width:55%;
                padding-left:25px;
                h2 {
                    margin-top:0;
                }
                p, ul {
                    padding:0;
                    width:100%;
                    float:left;
                }
            }
            .banner-zone {
                float:left;
                width:45%;
                ul {
                    width:100%;
                    float:left;
                    li {
                        float:left;
                        margin:0;
                    }
                }
                img {
                    padding:15px;
                    border:1px solid #EEE;
                    width:100%;
                    background:#FFF;
                    border-radius:3px;
                }
            }
            @media(max-width: 500px){
                .banner-zone, .text-zone {
                    width:100%;
                    margin:0;
                    padding:0;
                    li {
                        padding:0;
                    }
                }
            }
        }
    }


}