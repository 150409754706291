.inner-nav, .home {

    /* Header */
    header {
        position:fixed;
        top:0;
        left:0;
        width:100%;
        z-index:10001;
        background:#000;
        padding:0 25px;
        @include transition(all .2s);
        @include media($tablet) {
            border-bottom:1px solid rgba(#FFF, .05);
        }
        a.logo {
            display: block;
            img {
                height:32px;
                @include transition(.2s all);
            }
            @media(min-width:1024px) {
                img {
                    height:55px;
                }
            }
        }
        .search-wrap {
            display: none;
            @media(min-width:1024px) {
                display: block;
            }
        }
        .search {
            form {
                display: flex;
                margin:0;
            }
            input {
                width:400px;
                border:0px;
                height:40px;
                text-indent:10px;
                color:$spab;
                @include font-size(14px);
                @include transition(.2s all);
            }
            button {
                align-items:center;
                background:#FFF;
                border-radius:0;
                color:$secondary;
                border:none;
                display: flex;
                height:40px;
                justify-content: center;
                margin:0;
                padding:0;
                width:40px;
                
                @include font-size(18px);
                @include transition(.2s all);
                &:hover {
                    color:$primary;
                }
            }
        }

        .top-cta {
            align-items:center;
            display: flex;
            justify-content: space-between;
            max-width:1200px;
            margin:0px auto 0;
            padding:11px 0;
            position:relative;
            @include transition(.2s all);
            width:100%;
            z-index:2;
            @include media($tablet) {
                pointer-events:none;
                margin:0 auto;
            }
            ul {
                display: flex;
                margin:0 -5px 0 0;
                padding:0;
                @include media($tablet) {
                    display:none;
                }
                li {
                    list-style: none;
                    margin: 0 5px;
                    a {
                        border:2px solid #BEC0C5;
                        border-radius:50%;
                        color:#BEC0C5;
                        display:block;
                        @include font-size(15px);
                        height:40px;
                        line-height:38px;
                        padding:0;
                        text-align:center;
                        width:40px;
                        &:hover {
                            background:transparent;
                            border-color:$secondary;
                        }
                    }
                    &:hover {
                        a {
                            background:transparent;
                        }
                    }
                }
            }
            @media(min-width:1024px) {
                padding:15px 0;
            }
        }

        /* Navigation */
        .nav-wrap {
            width:100%;
            max-width:1200px;
            margin:0 auto;
            overflow:hidden;
            padding:0;
            nav {
                width:100%;
                padding:0;

                /* Navigation */
                ul.top-level {
                    margin:0 0 0 -15px;
                    padding:0;
                    li {
                        display: inline-block;
                        list-style: none;
                        margin:0;
                        position:relative;
                        a {
                            color:#BEC0C5;
                            display: block;
                            font-weight:bold;
                            font-family:$heading;
                            letter-spacing: 1px;
                            padding:10px 15px;
                            
                            text-transform: uppercase;
                            
                            
                            @include font-size(12px);
                            @include transition(.3s background);
                            &:hover {
                                background:transparent;
                            }
                            i {
                                margin-right:5px;
                            }
                        }
                        span.mobile-toggle {
                            align-items:center;
                            display: flex;
                            justify-content: center;
                            position:absolute;
                            top:1px;
                            right:0;
                            width:60px;
                            height:51px;
                            z-index: 99998;
                            border-left:1px solid rgba(#FFF, .1);
                            
                            text-align:center;
                            line-height:49px;
                            color:#BEC0C5;
                            cursor:pointer;
                            .fa-minus {
                                display: none;
                            }
                            &:hover {
                                .fa-plus {
                                    display: none;
                                }
                                .fa-minus {
                                    display: block;
                                }
                            }
                            @media(min-width:1024px) {
                                display: none;
                            }
                        }
                        div.second-level {
                            display:none;
                            position:absolute;
                            padding:0 30px;
                            top:50px;
                            right:0;
                            background:darken(#2A3140, 5%);
                            width:100%;
                            box-shadow: 0 6px 7px 0 rgba(0,0,0,.16);
                            -moz-box-shadow: 0 6px 7px 0 rgba(0,0,0,.16);
                            -webkit-box-shadow: 0 6px 7px 0 rgba(0,0,0,.16);
                            z-index: 999999;
                            @media(min-width:1024px) {
                                top:126px;
                            }
                            > ul {
                                display:flex;
                                max-width: 1240px;
                                width: 100%;
                                margin:0 auto;
                                padding:15px 0;
                            }
                            li {
                                h3 {
                                    width:100%;
                                    padding:0 5px 15px;
                                    margin:0 0 15px;
                                    color:#FFF;
                                    border-bottom:1px solid rgba(#FFF, .3);
                                    @include font-size(18px);
                                }
                                ul.third-level {
                                    margin:0;
                                    padding:0;
                                    li {
                                        padding:0;
                                        margin:0;
                                        width:100%;
                                        border:0;
                                        a {
                                            width:100%;
                                            font-family: $ffamily;
                                            text-transform: capitalize;
                                            padding:10px 5px;
                                            line-height:14px;
                                            border:0;
                                            background:none;
                                            font-weight:600;
                                            color:#FFF;
                                            @include font-size(13px);
                                            &:hover {
                                                color:$secondary;
                                            }
                                            &.vall {
                                                color:$secondary;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &:hover {
                            a {
                                z-index:10002;
                                position:relative;
                                color: #FFF;
                            }
                            div.second-level {
                                display:block;
                            }
                            .fa-plus {
                                display: none;
                            }
                            .fa-minus {
                                display: block;
                            }
                        }

                        &.cart, &.quick-order, &.login {
                            a {
                                &:hover {
                                    color:#FFF;
                                }
                            }
                            .cart-drop {
                                display:none;
                            }
                        }

                        &.login {
                            a {
                                padding-right:0;
                            }
                        }

                        &.fright {
                            float:right;
                        }
                        @media only screen and (min-width: 1024px) {
                            &.top-nav-anchor {
                                position:inherit;
                                &:hover {
                                    ul.second-level {
                                        display:block;
                                    }
                                }
                            }
                        }
                    }
                }

                /* Mobile / tablet Menu */
                ul.mobile-menu {
                    display:none;
                    height:60px;
                    flex-direction: row-reverse;
                    justify-content: flex-start;

                    margin:0;
                    padding:0;
                    width:100%;
                    
                    li {

                        width:60px;
                        height:60px;
                        display:block;
                        border-left:1px solid rgba(#FFF, .05);
                        text-align:center;
                        a {
                            color:#FFF;
                            line-height:60px;
                            display:block;
                            width:60px;
                            height:60px;
                            @include font-size(20px);
                            @include transition(.2s background);
                            &:hover {
                                background:darken(#2A3140, 2%);
                            }
                            &.cart {
                                position:relative;
                                span {
                                    position:absolute;
                                    top:8px;
                                    right:8px;
                                    line-height:11px;
                                    display:block;
                                    border-radius:50%;
                                    width:20px;
                                    height:20px;
                                    padding:3px;
                                    font-weight:bold;
                                    text-align:center;
                                    @include font-size(12px);
                                }
                            }
                        }
                    }

                    .search {
                        cursor:pointer;
                        @include transition(.2s all);
                        &:hover {
                            background:darken(#2A3140, 2%);
                        }
                       .search-toggle {
                            color:#FFF;
                            width:60px;
                            height:60px;
                            line-height:60px;
                            @include font-size(20px);
                        }
                        .search-drop {
                            position:absolute;
                            top:60px;
                            left:0;
                            width:100%;
                            padding:15px;
                            background:#2A3140;
                            border-top:1px solid rgba(#FFF, .05);
                            display:none;
                            input {
                                width:100%;
                                border:0px;
                                height:50px;
                                line-height:50px;
                                padding:0;
                                text-indent:10px;
                                color:$spab;
                                @include font-size(14px);
                                @include transition(.2s all);
                                border-radius:0;
                                -webkit-appearance:none;
                                -webkit-border-radius:none;
                            }
                            button {
                                height:50px;
                                width:50px;
                                position:absolute;
                                top:15px;
                                right:15px;
                                background:#FFF;
                                color:$secondary;
                                border:0;
                                @include font-size(22px);
                                @include transition(.2s all);
                                -webkit-appearance:none;
                                &:hover {
                                    color:$secondary;
                                }
                            }
                            &.active {
                                display:block;
                            }
                        }
                    }

                    li.menu-toggle {
                        float:right;
                        text-align:center;
                        display:block;
                        width:60px;
                        cursor:pointer;
                        border-left:1px solid rgba(#FFF, .05);
                    }

                    div.search {
                        float:right;
                    }
                }
            }

            /* Responsive */
            @include media($tablet) {
                position:absolute;
                top:0;
                right:0;
                overflow:visible;
                padding:0;
                span.mobile-toggle {
                    display: block;
                }
                nav {
                    background:#2A3140;
                    height:auto;
                    overflow:hidden;
                    width:100%;
                    ul.mobile-menu {
                        display:flex;
                    }
                    ul.top-level {
                        display:none;
                        margin:0;
                        width:100%;
                        border-bottom:1px solid rgba(#FFF, .05);
                        position:fixed;
                        top:60px;
                        overflow:scroll;
                        height:calc(100% - 59px);
                        &::-webkit-scrollbar {
                            display:none;
                        }
                        li {
                            position:relative;
                            width:100%;
                            margin:0;
                            a {
                                width:100%;
                                padding:15px 25px;
                                background:#2A3140;
                                cursor:pointer;
                                border:0;
                                border-top:1px solid rgba(#FFF, .05);
                                &:hover {
                                    color:#FFF;
                                    background:darken(#2A3140, 2%);
                                }
                            }
                           ul.second-level {
                                display:none;
                                position:relative;
                                float: left;
                                width: 100%;
                                top: 0;
                                box-shadow: none;
                                -moz-box-shadow: none;
                                -webkit-box-shadow: none;
                                li {
                                    width:33.3333%;
                                    @include media($mobile) {
                                        width:100%;
                                    }
                                    ul.third-level {
                                        li {
                                            a {
                                                width:auto;
                                                float:left;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    display:block;
                                }
                            }
                            &:hover {
                                ul.second-level {
                                    display:none;
                                }
                            }
                            &.fright {
                                display:none;
                            }
                            &.active {
                                a {
                                    border-bottom:1px solid rgba(#FFF, .1);
                                }
                                span.mobile-toggle .fa-minus {
                                    display:block;
                                }
                                span.mobile-toggle .fa-plus {
                                    display: none;
                                }
                                ul.second-level {
                                    display:block;
                                }
                            }
                        }
                        &.active {
                            display:block;
                        }
                    }
                }
            }
        }

        
    }
}

body.down .inner-nav  header {
    background:rgba(#2A3140, 1);
    box-shadow: 0 6px 7px 0 rgba(0,0,0,.1);
    -moz-box-shadow: 0 6px 7px 0 rgba(0,0,0,.1);
    -webkit-box-shadow: 0 6px 7px 0 rgba(0,0,0,.1);
}

.quick-order-pop {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(#000, .75);
    z-index:99999;
    opacity:0;
    visibility:hidden;
    @include transition(.2s);
    &.active {
        opacity:1;
        visibility:visible;
    }
    .form-wrap {
        background:#FFF;
        max-width:500px;
        width:100%;
        @include vertical-align();
        position:absolute;
        left:50%;
        margin-left:-250px;
        h3 {
            padding:0 25px;
            line-height:70px;
            border-bottom:1px solid #EEE;
            background:#FAFAFA;
            color:$primary;
            @include font-size(22px);
            i {
                float:right;
            }
        }
        form {
            padding:25px;
            .order-row-wrap {
                float:left;
                width:100%;
                overflow:hidden;
                .order-row {
                    float:left;
                    width:100%;
                    border:1px solid #EEE;
                    border-bottom:0;
                    position:relative;
                    input {
                        width:calc(100% - 50px);
                        float:left;
                        line-height:40px;
                        border-right:1px solid #EEE;
                        text-indent:10px;
                        @include transition(.2s);
                        &.qty {
                            border-right:0;
                            width:50px;
                            text-align:center;
                            background:#FAFAFA;
                            text-indent:0;
                            color:$primary;
                        }
                        &:focus {
                            background: #FAFAFA;
                        }
                    }
                }
            }
            a {
                float:left;
                display:block;
                line-height:40px;
                border:1px solid #EEE;
                padding:0 15px;
                width:100%;
                color:$primary;
                text-align:center;
                margin:0 0 15px;
                @include font-size(13px);
                @include transition(.2s);
                &:hover {
                    background:#FAFAFA;
                }
            }
            .submit-row {
                float:left;
                width:100%;
                margin:10px 0 25px;
                button {
                    width:100%;
                    background:$success;
                    color:#FFF;
                    line-height:40px;
                    text-align:center;
                    font-weight:bold;
                    border:0;
                    border-radius:3px;
                    @include font-size(18px);
                    @include transition(.2s);
                    &:hover {
                        background:darken($success, 10%);
                    }
                }
            }
        }
    }
}