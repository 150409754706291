#wrap.my-account {
    width:100%;
    float:left;
    header {
        border-bottom:1px solid rgba(#FFF, .1);
    }
    table {
        @include font-size(13px);
    }
    .my-account-overview {
        width:100%;
        float:left;
        padding:75px 0 100px;
        background:darken(#2A3140, 3%);
        position:relative;
        z-index:11;
        margin-top:128px;
        @include media($tablet) {
            padding:40px 0 75px;
            margin-top:60px;
        }
        @include media($mobileL) {
            padding:25px 0 75px;
        }
        @include media($mobile) {
            padding:25px 0;
        }
        .inner-wrap {
            border:0;
            padding:0 25px;
            margin:0 auto;
            overflow: visible;
            .account-details {
                width:65%;
                float:left;
                margin-top:15px;
                @include media($tabletP) {
                    width:100%;
                    float:left;
                }
                @include media($mobile) {
                    display:none;
                }
                ul {
                    @include media($tabletP) {
                        text-align:center;
                    }
                    li {
                        list-style: none;
                        width:175px;
                        float:right;
                        border-right:1px solid rgba(#FFF, .2);
                        position:relative;
                        padding-right:25px;
                        margin-right:35px;
                        .svg-wrap, i {
                            float:left;
                            display:block;
                            width:35px;
                            height:35px;
                            margin-right:10px;
                            color:$healthc;
                            position:absolute;
                            top:0;
                            left:0;
                            @include font-size(30px);
                            svg {
                                position:absolute;
                                top:0;
                                left:0;
                                width:100%;
                                height:40px;
                                width:40px;
                            }
                        }
                        p {
                            float:left;
                            color:#FFF;
                            padding-left:50px;
                            @include font-size(13px);
                            span {
                                margin-top:5px;
                                text-transform: uppercase;
                                float:left;
                                width:100%;
                                color: rgba(#FFF, .5);
                                @include font-size(12px);
                            }
                        }
                        &:first-child {
                            margin:0;
                            border:0;
                            i {
                                color: $cateringh;
                            }
                        }
                        &:last-child {
                            i {
                                color: $smartw;
                            }
                        }
                        @include media($tabletP) {
                            display:inline-block;
                            text-align:center;
                            float:none;
                            padding:45px 0 0;
                            margin:0;
                            border:0;
                            .svg-wrap {
                                top:0;
                                left:50%;
                                margin-left:-17.5px;
                            }
                            p {
                                padding:0;
                                width:100%;
                            }
                        }
                    }
                }
            }
            .details {
                float:left;
                width:35%;
                position:relative;
                .flagged {
                    position:absolute;
                    top:-60px;
                    left:0;
                    padding:7.5px;
                    background:$error;
                    p {
                        margin:0;
                        color:#FFF;
                        @include font-size(12px);
                        i {
                            float:left;
                            margin-right:10px;
                        }
                    }
                }
                &:before {
                    content:"";
                    height:53px;
                    width:1px;
                    border-right:1px solid rgba(#FFF, .2);
                    position:absolute;
                    top:0;
                    left:65px;
                    @include media($tabletP) {
                        display:none;
                    }
                }
                .user-icon {
                    display:block;
                    position:absolute;
                    top:0;
                    left:0;
                    width:50px;
                    height:50px;
                    border-radius:50%;
                    overflow:hidden;
                    text-align:center;
                    img {
                        display:inline-block;
                        width:90%;
                        float:left;
                        border-radius: 50%;
                        margin:2.5px;
                    }
                    @include media($tabletP) {
                        position:relative;
                        float:none;
                        width:50px;
                        display:inline-block;
                        img {
                            float:none;
                            margin:0;
                        }
                    }
                }
                h2 {
                    color:#FFF;
                    float:left;
                    width:100%;
                    margin:0;
                    padding-left:90px;
                    @include font-size(32px);
                }
                span {
                    opacity:.8;
                    color:#FFF;
                    padding-left:90px;
                    @include font-size(16px);
                }
                @include media($tabletP) {
                    width:100%;
                    margin:0 0 25px;
                    text-align:center;
                    h2, span {
                        padding-left:0;
                    }
                }
                @include media($mobileL) {
                    margin:0 0 10px
                }
            }
            .menu {
                position:absolute;
                bottom:0;
                left:0;
                width:100%;
                padding:0;
                nav {
                    max-width:1250px;
                    margin:0 auto;
                    padding:0 25px;
                    ul {
                        float:left;
                        width:100%;
                        @include media($tabletP) {
                            text-align:center;
                        }
                        li {
                            list-style: none;
                            float:left;
                            margin-right:50px;
                            @include media($tabletP) {
                                margin:0 20px -2px;
                                display:inline-block;
                                float:none;
                            }
                            @include media($mobileL) {
                                margin:0 12.5px -2px;
                            }
                            a {
                                float:left;
                                width:100%;
                                padding:10px 0;
                                color:#FFF;
                                font-weight:bold;
                                font-family:$heading;
                                text-transform: uppercase;
                                border-bottom:1px solid $secondary;
                                @include font-size(13px);
                                line-height:21px;
                                opacity:.7;
                                border-bottom:2px solid transparent;
                                @include transition(all .2s);
                                i {
                                    margin-left:10px;
                                    color:#FFF;
                                    float:right;
                                    @include font-size(18px);
                                }
                                &:hover {
                                    opacity:1;
                                    border-bottom:2px solid $secondary;
                                }
                            }
                            &.active {
                                a {
                                    opacity:1;
                                    border-bottom:2px solid $secondary;
                                }
                            }
                            &:last-child {
                                float:right;
                                margin:0;
                                @include media($tabletP) {
                                    float:none;
                                    margin:0 15px -2px;
                                }
                            }
                            @include media($mobile) {
                                width:100%;
                                margin:0 0 5px;
                                a {
                                    border:1px solid rgba(#FFF, .1);
                                }
                                &.active, &:hover {
                                    a {
                                         border:1px solid $secondary;
                                    }
                                }
                                &:last-child {
                                    margin:0;
                                }
                            }
                        }
                    }
                }
                @include media($mobile) {
                    position:relative;
                    float:left;
                    width:100%;
                    margin:25px 0 0;
                    nav {
                        padding:0;
                        float:left;
                        width:100%;
                    }
                }
            }
        }
    }
    .inner-wrap {
        max-width:1250px;
        margin:105px auto 0;
        padding:0 25px;
        border-top:2px solid #FFF;
        .my-account-wrap {
            margin:40px 0;
            float:left;
            width:100%;
            .account {
                .widget {
                    h2 {
                        font-weight:600;
                        margin:0 0 20px;
                        padding:0 0 15px;
                        color:#2A3140;
                        border-bottom:1px solid #DDD;
                        @include font-size(20px);
                        line-height:24px;
                        i {
                            float:right;
                            margin-top:-2px;
                            @include font-size(22px);
                        }
                    }
                    table {
                        width:100%;
                        border:1px solid #DDD;
                        margin:0 0 25px;
                        &:last-child {
                            margin:0;
                        }
                        tbody {
                            tr {
                                td {
                                    line-height:22px;
                                    color:#888;
                                    border-right:1px solid #DDD;
                                    border-bottom:1px solid #DDD;
                                    padding:10px 25px 10px 10px;
                                    &:last-child {
                                        color: $healthc;
                                    }
                                    &.negative, &.error {
                                        color:$error;
                                    }
                                    &.info {
                                        color:$healthc;
                                    }
                                    &.success {
                                        color:$success;
                                    }
                                    a {
                                        color:#009C25;
                                        @include font-size(14px);
                                        @include transition(all .2s);
                                        i {
                                            margin-left:5px;
                                        }
                                        &:hover {
                                            color:darken(#009C25, 5%);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    form {
                        margin:0 0 25px;
                        float:left;
                        width:100%;
                        &:last-child {
                            margin:0;
                        }
                        .input-wrap {
                            overflow:hidden;
                            margin:0 auto 10px;
                            position:relative;
                            img {
                                position:absolute;
                                top:0;
                                right:0;
                                border-right:1px solid #DDD;
                            }
                            label {
                                width:40%;
                                float:left;
                                height:50px;
                                padding:0 15px;
                                border:1px solid #DDD;
                                border-right:0px;
                                line-height:50px;
                                color:#333;
                                @include font-size(13px);
                                em {
                                    color:$error;
                                }
                            }
                            label.error {
                                display:none !important;
                            }
                            input {
                                float:right;
                                height:50px;
                                line-height:50px;
                                text-indent:10px;
                                width:60%;
                                background:#F8F8F8;
                                border:1px solid #DDD;
                                @include transition(all .2s);
                                &:focus {
                                    outline:none;
                                    border:1px solid $secondary;
                                    color:$secondary;
                                }
                                &.error {
                                    color:#FFF;
                                    border-color:$error;
                                    background: rgba($error, .5);
                                    @include placeholder {
                                        color:$error;
                                    }
                                }
                            }
                            label.file {
                                float:right;
                                height:50px;
                                width:60%;
                                border:1px solid #DDD;
                                background:#F8F8F8;
                                color:#A9A9A9;
                                cursor:pointer;
                                padding: 0 65px 0 0px;
                                text-indent:10px;
                                @include transition(all .2s);
                            }
                            input[type="file"] {
                                visibility: hidden;
                                opacity:0;
                                display:none;
                            }
                            &.selected {
                                label.file {
                                    color:#333;
                                }
                            }
                        }
                        .submit-zone {
                            margin:10px 0 0;
                        }
                    }
                    .submit-zone, .button-zone {
                        margin:20px 0 0;
                        padding:20px 0 0;
                        border-top:1px solid #EEE;
                        float:left;
                        width:100%;
                        .gbtn {
                            float:right;
                            background:$success;
                            border-radius:3px;
                            border:0;
                            @include font-size(13px);
                            i {
                                margin-right:5px;
                            }
                            &:hover {
                                color:#FFF;
                                background: darken($success, 10%);
                            }
                        }
                    }
                }
                .chart-widget {
                    h2 {
                        font-weight:600;
                        margin:0 0 20px;
                        padding:0 0 15px;
                        color:#2A3140;
                        border-bottom:1px solid #DDD;
                        @include font-size(24px);
                        i {
                            float:right;
                            margin-top:-2px;
                            @include font-size(22px);
                        }
                    }
                    .chart-wrap {
                        width:100%;
                        border:1px solid #DDD;
                        padding:25px;
                        canvas {
                            width:100%;
                            height:100%;
                            margin-left:-10px;
                        }
                    }
                }
                .address-list {
                    @include span-columns(12);
                    ul {
                        padding:10px 0;
                        float:left;
                        width:100%;
                        li {
                            list-style: none;
                            border:1px solid #DDD;
                            @include span-columns(4);
                            margin-bottom:15px;
                            @include media($tablet) {
                                @include span-columns(6);
                                margin-bottom:15px;
                            }
                            @include media($mobile) {
                                @include span-columns(12);
                                margin-bottom:15px;
                                &:last-child {
                                    margin-bottom:0;
                                }
                            }
                            h3 {
                                color:#2A3140;
                                margin:0 0 15px;
                                padding:15px;
                                border-bottom:1px solid #DDD;
                                @include font-size(18px);
                                a {
                                    float:right;
                                    margin-left:15px;
                                    &.edit {
                                        color: $success;
                                    }
                                    &.delete {
                                        color:$error;
                                    }
                                }
                            }
                            address {
                                padding:0 15px 15px;
                                line-height:22px;
                                color:#666;
                                min-height:86px;
                                strong {
                                    font-weight:bold;
                                    @include font-size(16px);
                                    margin:0 0 5px;
                                    float:left;
                                    width:100%;
                                    color:#333;
                                    display:block;
                                }
                            }
                        }
                    }
                }
                .order-list {
                    @include span-columns(8);
                    @include media($tabletP) {
                        width:100%;
                        margin:0 0 25px;
                    }
                    table {
                        thead {
                            tr {
                                th {
                                    background: #F8F8F8;
                                    color:#333;
                                    border-right:1px solid #DDD;
                                    border-bottom:1px solid #DDD;
                                    padding:10px 25px 10px 10px;
                                }
                            }
                        }
                        tbody {
                            tr {
                                td {
                                    line-height:22px;
                                }
                            }
                        }
                    }
                }
              .order-list-open {
                @include span-columns(12);
                @include media($tabletP) {
                  width:100%;
                  margin:0 0 25px;
                }
                table {
                  thead {
                    tr {
                      th {
                        background: #F8F8F8;
                        color:#333;
                        border-right:1px solid #DDD;
                        border-bottom:1px solid #DDD;
                        padding:10px 25px 10px 10px;
                      }
                    }
                  }
                  tbody {
                    tr {
                      td {
                        line-height:22px;
                      }
                    }
                  }
                }
              }
                .account-details {
                    padding:0;
                    @include span-columns(4);
                    @include media($tabletP) {
                        width:100%;
                        margin:0 0 25px;
                    }
                }
                .purchase-history {
                    padding:0;
                    @include span-columns(12);
                    @include media($tabletP) {
                        width:100%;
                        margin:0 0 25px;
                    }
                }
                .account-settings {
                    @include span-columns(6);
                    @media screen and (max-width: 1000px) {
                        width:100%;
                        margin:0 0 25px;
                    }
                }
            }
        }
    }
}

@include media($mobile) {
    $breakpoint-alpha: 550px;
    .rwd-table {
      margin: 1em 0;
      overflow:hidden;
      min-width: 280px; // adjust to your needs

      tr {
        border-top: 1px solid #ddd;
      }

      th {
        display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!
      }

      td {
        display: block;

        &:first-child {
          padding-top: .5em;
        }
        &:last-child {
          padding-bottom: .5em;
          border-bottom:0 !important;
        }

        &:before {
          content: attr(data-th)" "; // who knew you could do this? The internet, that's who.
          font-weight: bold;
          width: 6.5em;
          display: inline-block;
          color:$primary;
          // end options

          @media (min-width: $breakpoint-alpha) {
            display: none;
          }
        }
      }

      th, td {
        text-align: left;

        @media (min-width: $breakpoint-alpha) {
          display: table-cell;
          padding: .25em .5em;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }

      }
    }
}
