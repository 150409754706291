.breadcrumbs {
    width:100%;
    padding:0;
    overflow:hidden;
    background:darken(#2A3140, 5%);
    margin-top:126px;
    z-index:10;
    position:relative;
    @include media($tablet) {
        margin: 60px auto 0;
    }
    ul {
        max-width:1240px;
        margin:0 auto;
        padding:0 15px;
        li {
            display: inline-block;
            list-style: none;
            line-height: 1;
            margin: 0;
            padding-right:0px;
            position:relative;
            a {
                align-items:center;
                color:#FFF;
                display: flex;
                font-weight:300;
                font-size: 0.75em;
                letter-spacing: 0.5px;
                padding:10px 0 10px 10px;
                text-transform: uppercase;
                &:hover {
                    text-decoration: underline;
                }
                &:after {
                    color:#FFF;
                    content:"»";
                    margin-left:10px;
                    transform:translateY(-1px);
                }
            }
            h1 {
                color:#FFF;
                font-weight:bold;
                line-height:46px;
                @include font-size(18px);
            }
            
            &:last-child {
                a {
                    &:after {
                        display:none;
                    }
                }
            }
        }
    }
    @media(min-width:1024px) {
        margin-top:131px;
        ul {
            li {
                a {
                    font-size:0.875em;
                    font-weight: 600;
                    padding:15px 0 15px 10px;
                }
            }
        }
    }
}