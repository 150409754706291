.newsletter-signup {
    padding:60px 25px;
    width:100%;
    background:#EEE;
    border-top:1px solid rgba(#999, .1);
    h3 {
        color:#666;
        @include font-size(26px);
        font-weight:400;
        text-align:center;
        margin:0 0 20px;
        line-height:1;
    }
    form {
        display: flex;
        width:400px;
        max-width:100%;
        margin:0 auto;
        overflow:hidden;
        position:relative;
        input {
            width:100%;
            line-height:40px;
            height:42px;
            text-indent:10px;
            padding-right:120px;
            border:0px;
            border-radius:3px;
            @include font-size(14px);
            @include transition(0.2s all);
            @include media($tablet) {
                text-indent:5px;
            }
            &:focus {
                background:#F9F9F9;
                color:$secondary;
            }
        }
        button {
            border:0;
            background:$secondary;
            color:#FFF;
            margin:0;
            text-transform: uppercase;
            font-weight:bold;
            padding:0 25px;
            border-radius:0 3px 3px 0;
            @include font-size(14px);
            @include transition(0.2s all);
            &:hover {
                background:darken($secondary, 10%);
            }
            @include media($mobile) {
                padding:0 15px;
            }
        }
    }
}

footer {
    width:100%;
    background:#999;
    padding:50px 0 25px;
    border-top:1px solid darken(#999, 5%);
    .foot-wrap {
        max-width: 1200px;
        margin: 0 auto;
        padding:0 75px;
        overflow: hidden;
        @include media($mobile) {
            padding:0 25px;
        }
        .bottom-stats {
            display: flex;
            justify-content: space-between;
            width:100%;
            padding:0;
            margin:0 auto;
            ul {
                margin:0;
                padding:0;
                @include media($mobile) {
                    text-align:center;
                    float:none;
                }
                li {
                    display: inline-block;
                    margin-bottom: 0;
                    list-style: none;
                    margin-right:10px;
                    @include media($mobile) {
                        display:inline-block;
                        float:none;
                    }
                    a {
                        padding-right:10px;
                        border-right:1px solid rgba(#999, .5);
                        color:#FFF;
                        line-height:24px;
                        @include font-size(12px);
                        @include transition(.2s all);
                        &:hover {
                            color:#666;
                        }
                    }
                    &:last-child {
                        a {
                            border:0;
                        }
                    }
                }
            }
            p {
                margin:0;
                line-height:24px;
                color:#FFF;
                font-weight:600;
                text-align:right;
                @include font-size(12px);
                @include media($mobile) {
                    width:100%;
                    text-align:center;
                }
                a {
                    margin-right:5px;
                    color:#FFF;
                    @include transition(.2s all);
                    @include font-size(14px);
                    &:hover {
                        color:$secondary;
                    }
                }
            }
        }
        .three-col.navigation-zone {
            padding:0 0 35px;
            width: 100%;
            margin:0 auto 25px;
            overflow:hidden;
            border-bottom:1px solid rgba(#AAA, .3);
            h3 {
                line-height:32px;
                color: #FFF;
                margin:0 0 15px;
                
                width:100%;
                text-align:left;
                font-weight:400;
                @include font-size(18px);
                @include media($mobile) {
                    text-align:center;
                }
            }

            ul {
                width:100%;
                margin:0;
                padding:0;
                li {
                    display: inline-block;
                    width:100%;
                    list-style: none;
                    letter-spacing: 1px;
                    margin:0;

                    a {
                        display: block;
                        text-transform: uppercase;
                        text-align:left;
                        width:100%;
                        font-weight:300;
                        color:#EAEAEA;
                        line-height:22px;
                        @include font-size(12px);
                        @include transition(all .2s);
                        text-decoration: none;
                        @include media($mobile) {
                            text-align:center;
                        }
                        &:hover {
                            color:$primary;
                        }
                    }
                }
            }

            .double {
                @include span-columns(6);
                ul {
                    display: flex;
                    flex-wrap:wrap;
                    li {
                        width:50%;
                        @include media($mobile) {
                            width:100%;
                        }
                    }
                }

                @include media($mobile) {
                    text-align:center;
                    @include span-columns(12);
                    margin-bottom:25px;
                    padding-bottom:25px;
                    border-bottom:1px solid #9F9F9F;
                    &:last-child {
                        margin-bottom:0;
                        padding-bottom:0;
                        border-bottom:0;
                    }
                }
            }
            .single {
                @include span-columns(3);
                @include media($mobile) {
                    text-align:center;
                    @include span-columns(12);
                    margin-bottom:25px;
                    padding-bottom:25px;
                    border-bottom:1px solid #9F9F9F;
                    &:last-child {
                        margin-bottom:0;
                        padding-bottom:0;
                        border-bottom:0;
                    }
                }
            }
        }
    }
}