#wrap.home {
    width:100%;
    overflow:hidden;
    .inner-wrap {
        padding:0;
    }
    .banner-zone {
        width:100%;
        position:relative;
        margin: 130px auto 15px;
        padding:0;
        max-width: 1200px;
        @include media($tablet) {
          margin-top:60px;
        }
        #carousel-new {
            display: none;
        }
        #slider-new {
            display: block;
            float:none;
            position: relative;
            width:100%;
            .flex-viewport {
                float:none;
            }
            .flex-direction-nav {
                
                li {
                    background-color: $primary;
                    list-style: none;
                    margin:0;
                }
                .flex-next {
                    background-image:url(https://www.oneltd.com/app/themes/one-creative-environments/src/images/icons/chev-circle-right-white.svg);
                    opacity: 1;
                    right:30px;
                        text-indent: 9999px;
                    &:before {
                        display: none;
                    }
                }
                .flex-prev {
                    background-image:url(https://www.oneltd.com/app/themes/one-creative-environments/src/images/icons/chev-circle-left-white.svg);
                    opacity: 1;
                    left:30px;
                        text-indent: -9999px;
                    &:before {
                        display: none;
                    }
                }
            }
            ul.slides {
                li {
                    list-style: none;
                    padding:0;
                    max-height:700px;
                    height:300px;
                    // float:left;
                    width:100%;
                    background-repeat:no-repeat;
                    background-position:center;
                    -webkit-background-size: cover;
                    -moz-background-size: cover;
                    -o-background-size: cover;
                    background-size: cover;
                    position:relative;
                    // @include media($tablet) {
                    //     height:calc(100vh - 60px);
                    // }
                    &:before {
                        content:"";
                        position:absolute;
                        top:0px;
                        left:0;
                        height:150px;
                        width:100%;
                        z-index:1;
                        opacity:1;
                        background-image: linear-gradient(-180deg, #000 0%, rgba(42, 49, 64, 0) 100%);
                        @include media($tablet) {
                            top:0;
                        }
                    }
                    .text-zone {
                        @include vertical-align();
                        width:100%;
                        float:left;
                        margin-top:0;
                        position:relative;
                        z-index:3;
                        h2 {
                            line-height:64px;
                            @include font-size(48px);
                            font-family: $heading;
                            text-transform: uppercase;
                            font-weight:bold;
                            letter-spacing:1px;
                            color:#FFF;
                            padding-bottom:20px;
                            margin:0 0 20px;
                            position:relative;
                            text-align:center;
                            text-shadow:0 0 5px rgba(#000, .25);
                            @include media($tablet) {
                                line-height:54px;
                                @include font-size(30px);
                            }
                            @include media($mobile) {
                                line-height:44px;
                                @include font-size(20px);
                            }
                        }
                        a.gbtn {
                            width:210px;
                            margin:20px auto 0;
                            padding:16px 26px;
                            border-radius:50px;
                            font-family: $heading;
                            background:rgba(#000, .2);
                            @include font-size(14px);
                            &:hover {
                                background:#FFF;
                                color:$secondary;
                            }
                        }
                    }
                }
            }
        }
    }

    .our-brands {
        float:left;
        width:100%;
        position:relative;
        padding:0 25px 40px;
        overflow:hidden;
        h3 {
            text-align:center;
            width:100%;
            color:#333;
            margin:0 0 10px;
            @include font-size(22px);
        }
        .flex-control-nav {
            bottom: 10px;
            display:none;
            li {
                list-style: none;
                margin:0 4px;
                a {
                    width:8px;
                    height:8px;
                }
                a.flex-active {
                    background:$primary;
                }
            }
        }
        h2 {
            margin:0 0 25px;
            padding:0 0 25px;
            text-align:left;
            float:left;
            width:100%;
            color:$primary;
            font-weight:200;
            border-bottom:1px solid #EEE;
            position:relative;
            @include font-size(24px);
            a {
                position:absolute;
                top:-8px;
                right:0;
                @include font-size(16px);
                color:$primary;
                font-weight:600;
                padding:15px;
                line-height:14px;
                @include transition(all .2s);
                i {
                    float:right;
                    margin:-2px 0 0 10px;
                    @include font-size(18px);
                    line-height:14px;
                }
                &:hover {
                    color:$secondary
                }
            }
        }
        ul.slides {
            float:left;
            width:100%;
            margin-top:25px;
            li {
                list-style: none;
                text-align:center;
                img {
                    width: 50%;
                }
            }
        }
    }


    .blocks, .social-noslide {
        width:100%;
        float:left;
        margin:25px 0;
        padding:0 25px;
        .three-col {
            @include span-columns(4);
            height:275px;
            &:nth-child(3n) {
                margin-right:0;
            }
            @include media($tablet) {
                @include span-columns(6);
                &:nth-child(3n) {
                    margin-right: 2.35765%;
                }
                &:nth-child(2n) {
                    margin-right:0;
                }
                &:last-child {
                    display:none;
                }
            }
            @include media($mobile) {
                width:100%;
                margin:0 0 25px;
                height:250px;
            }
            > a, span.nolink-wrap {
                display:block;
                height:250px;
                width:100%;
                background-repeat:no-repeat;
                background-position:center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
                position:relative;
                overflow:hidden;
                &.advert-img {
                    img {
                        width:100%;
                    }
                }
            }
            &.sector {
                span {
                    content:'';
                    position:absolute;
                    width:200px;
                    height:200px;
                    border-radius:50%;
                    left:50%;
                    top:50%;
                    margin-left:-100px;
                    margin-top:-100px;
                    @include transition(.4s all);
                }
                h4 {
                    position:absolute;
                    top:0;
                    left:50%;
                    width:100%;
                    color:#FFF;
                    width:175px;
                    margin-left:-87.5px;
                    line-height:24px;
                    text-align:center;
                    text-transform: uppercase;
                    font-weight:bold;
                    @include font-size(18px);
                    letter-spacing:2px;
                    z-index:5;
                    @include vertical-align();
                }
                &:hover {
                    span {
                        -ms-transform: scale(3);
                        -webkit-transform: scale(3);
                        transform: scale(3);
                    }
                }
            }

            &.product {
                height:250px;
                background:#FFF;
                border:1px solid #EEE;
                overflow:hidden;
                a {
                    padding:25px;
                    position:relative;
                    .product-i-wrap {
                        position:absolute;
                        bottom:0;
                        left:0;
                        width:100%;
                        h2 {
                            width:100%;
                            padding:15px 25px;
                            z-index:2;
                            background:$secondary;
                            line-height: 24px;
                            overflow: hidden;
                            margin:0;
                            color:#FFF;
                            @include font-size(18px);
                            @include transition(.2s all);
                            span {
                                float:left;
                                width:100%;
                                color:rgba(#FFF, .85);
                                @include font-size(13px);
                            }
                        }
                        p.product-info {
                            color:$secondary;
                            text-align:right;
                            float:right;
                            margin:0;
                            padding:25px;
                            @include font-size(13px);
                            span {
                                float:right;
                                width:100%;
                                &.price {
                                    color:$success;
                                    margin-top:5px;
                                    font-weight:bold;
                                    @include font-size(18px);
                                }
                            }
                        }
                    }
                    img.product {
                        height:90%;
                        position:absolute;
                        top:0;
                        left:0;
                        opacity:1;
                        z-index:0;
                    }
                }
                &:hover {
                    .product-i-wrap {
                        h2 {
                            background:darken($secondary, 10%);
                        }
                    }
                }
            }

            &.twitter {
                background:#059FF5;
                position:relative;
                height:250px;
                span {
                    padding:25px;
                }
                a.tweeeturl {
                    @include font-size(13px);
                    float:right;
                    display:block;
                    color:#FFF;
                    font-style:normal;
                    font-weight:bold;
                    letter-spacing:1px;
                    text-transform: uppercase;
                    position:absolute;
                    top:25px;
                    right:25px;
                    @include transition(.2s all);
                    &:hover {
                        color:darken($secondary, 70%);
                    }
                }
                i {
                    color:lighten($secondary, 20%);
                    @include font-size(300px);
                    opacity:0.2;
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    text-align:center;
                    line-height:250px;
                    pointer-events: none;
                }
                blockquote {
                    a {
                        color:lighten($primary, 10%);
                        cursor:pointer;
                    }
                    color:#FFF;
                    text-align:right;
                    font-style:italic;
                    padding:15px 0 0 25px;
                    line-height:28px;
                    position:absolute;
                    bottom:25px;
                    right:25px;
                    @include font-size(16px);
                }
            }

        }
    }
}